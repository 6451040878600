import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import UserName from '../Commons/UserName'
import Navigation from '../Home/Navigation'
import './Draft.css'
import { ImageMemo } from '../Game/Components/Memos'
import { useLanguageContext } from '../Commons/Translations/languageContext'
import cardsData from '../Commons/Data/cards.json'
import { useHorizontalScroll, uuidv4, getCardFromCardData } from '../QRCodeRecognition/Functions'
import { CSSTransition } from 'react-transition-group'
import Button from '../Commons/Buttons'
import { Card } from 'react-bootstrap'
import LanguageSelect from '../Commons/Translations/LanguageSelect'
import DarkMode from '../Commons/DarkMode/DarkMode'
import { DraggedCard } from '../VirtualDeck/VirtualBoard'
import { ZoomedCard } from '../Game/GameReborn'
import FullScreenPanel from '../Commons/FullScreenPanel'
import { virtualBoardDefault } from '../Game/Components/GameDefaults'
import Resizable from '../Commons/Resizable'
import { doc, setDoc } from 'firebase/firestore'
import { db } from '../Commons/Firebase'

export default function Draft(props) {
    const [isDraftComplet, setIsDraftComplete] = useState(false)
    const testData = [
        {
            "name": "Treyst & Rossum",
            "image": "https://altered-prod-eu.s3.amazonaws.com/Art/COREKS/CARDS/ALT_CORE_B_AX_02/JPG/en_US/0a282a089b3d451d4f520e8e90853fc2.jpg",
            "images": {
                "en": "https://altered-prod-eu.s3.amazonaws.com/Art/COREKS/CARDS/ALT_CORE_B_AX_02/JPG/en_US/0a282a089b3d451d4f520e8e90853fc2.jpg",
                "fr": "https://altered-prod-eu.s3.amazonaws.com/Art/COREKS/CARDS/ALT_CORE_B_AX_02/JPG/fr_FR/a8ddcfbc6ec8d2466a3cca4a5c05c987.jpg",
                "es": "https://altered-prod-eu.s3.amazonaws.com/Art/COREKS/CARDS/ALT_CORE_B_AX_02/JPG/es_ES/a26ed235553634a38c2adb0107c8f47d.jpg",
                "it": "https://altered-prod-eu.s3.amazonaws.com/Art/COREKS/CARDS/ALT_CORE_B_AX_02/JPG/it_IT/fea0be777c7a8a0555cfdd4abd0696d0.jpg",
                "de": "https://altered-prod-eu.s3.amazonaws.com/Art/COREKS/CARDS/ALT_CORE_B_AX_02/JPG/de_DE/020cfb4a54994a7ab399667e0378ae41.jpg"
            },
            "id": "ALT_COREKS_B_AX_02_C",
            "type": "HERO",
            "manaCost": [
                0,
                0
            ],
            "power": [
                0,
                0,
                0
            ],
            "rarity": "COMMON",
            "faction": "AXIOM",
            "cardId": "0403b986-27e2-42e5-b868-205bae7d31ad"
        },
        {
            "name": "Bravos Tracer",
            "image": "https://altered-prod-eu.s3.amazonaws.com/Art/COREKS/CARDS/ALT_COREKS_B_BR_07/JPG/en_US/751595e098e6a2f1476ad92924434059.jpg",
            "images": {
                "en": "https://altered-prod-eu.s3.amazonaws.com/Art/COREKS/CARDS/ALT_COREKS_B_BR_07/JPG/en_US/751595e098e6a2f1476ad92924434059.jpg",
                "fr": "https://altered-prod-eu.s3.amazonaws.com/Art/COREKS/CARDS/ALT_COREKS_B_BR_07/JPG/fr_FR/993d2cc2498267e5dc36b9ec75f64c05.jpg",
                "es": "https://altered-prod-eu.s3.amazonaws.com/Art/COREKS/CARDS/ALT_COREKS_B_BR_07/JPG/es_ES/80051186974146ca769e5ea581321257.jpg",
                "it": "https://altered-prod-eu.s3.amazonaws.com/Art/COREKS/CARDS/ALT_COREKS_B_BR_07/JPG/it_IT/1d6092a1d929e8da409943388c7c5cc1.jpg",
                "de": "https://altered-prod-eu.s3.amazonaws.com/Art/COREKS/CARDS/ALT_COREKS_B_BR_07/JPG/de_DE/38f25bfd1627ea42ba3d707f85322dff.jpg"
            },
            "id": "ALT_COREKS_B_BR_07_R2",
            "type": "CHARACTER",
            "manaCost": [
                "2",
                "2"
            ],
            "power": [
                "3",
                "3",
                "3"
            ],
            "rarity": "RARE",
            "faction": "AXIOM",
            "cardId": "b190ff06-0239-453c-9b33-d898d7f2dd8f"
        },
        {
            "name": "Ganesha",
            "image": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_19/JPG/en_US/5a7b541db79a9cde316804947c3ff06c.jpg",
            "images": {
                "en": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_19/JPG/en_US/5a7b541db79a9cde316804947c3ff06c.jpg",
                "fr": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_19/JPG/fr_FR/efc0bfd083b1e3bc89167af436223bc3.jpg",
                "es": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_19/JPG/es_ES/5e104140198c825c324b07c86713d019.jpg",
                "it": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_19/JPG/it_IT/d303f9e36206999446aee2479cdd7e22.jpg",
                "de": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_19/JPG/de_DE/3fc20c03e6048e4a7eb6f9251b188359.jpg"
            },
            "id": "ALT_CORE_B_AX_19_C",
            "type": "CHARACTER",
            "manaCost": [
                "7",
                "7"
            ],
            "power": [
                "5",
                "5",
                "5"
            ],
            "rarity": "COMMON",
            "faction": "AXIOM",
            "cardId": "82b2cb87-65f1-4dba-8fce-2e3acc9875fd"
        },
        {
            "name": "Tinker Bell",
            "image": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_09/JPG/en_US/98b195c78bccb518a3a419b879b957d5.jpg",
            "images": {
                "en": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_09/JPG/en_US/98b195c78bccb518a3a419b879b957d5.jpg",
                "fr": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_09/JPG/fr_FR/345cbe5f4ef354e5cf1fd6dd0e596288.jpg",
                "es": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_09/JPG/es_ES/7233ffcf0463f6cb95d40759fbe2555d.jpg",
                "it": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_09/JPG/it_IT/122b0b581f33439e6b37f4d502d4dbe5.jpg",
                "de": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_09/JPG/de_DE/49ca21947a373ae9b2ec38c1c532f4fb.jpg"
            },
            "id": "ALT_CORE_B_AX_09_C",
            "type": "CHARACTER",
            "manaCost": [
                "3",
                "3"
            ],
            "power": [
                "1",
                "3",
                "3"
            ],
            "rarity": "COMMON",
            "faction": "AXIOM",
            "cardId": "b017f542-98bb-4839-8852-556ee8d25103"
        },
        {
            "name": "Amelia Earhart",
            "image": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_11/JPG/en_US/57757c194241d10245fe2877fd386070.jpg",
            "images": {
                "en": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_11/JPG/en_US/57757c194241d10245fe2877fd386070.jpg",
                "fr": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_11/JPG/fr_FR/219fe4f6843b28d466120e8d4f564e5f.jpg",
                "es": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_11/JPG/es_ES/460111054f975c0e5aa0241fac6fc3fc.jpg",
                "it": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_11/JPG/it_IT/d6df816dc2bbbd95b504aa636a63cabb.jpg",
                "de": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_11/JPG/de_DE/20744927c4324918db7aa01a8570323f.jpg"
            },
            "id": "ALT_CORE_B_AX_11_C",
            "type": "CHARACTER",
            "manaCost": [
                "3",
                "1"
            ],
            "power": [
                "2",
                "2",
                "2"
            ],
            "rarity": "COMMON",
            "faction": "AXIOM",
            "cardId": "8bdb4b9e-9ddc-4ec2-ba0d-9430a59e8b1b"
        },
        {
            "name": "Lyra Cloth Dancer",
            "image": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_LY_14/JPG/en_US/229c5602f2b4f47eb0025c669283b925.jpg",
            "images": {
                "en": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_LY_14/JPG/en_US/229c5602f2b4f47eb0025c669283b925.jpg",
                "fr": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_LY_14/JPG/fr_FR/ba7e35a80a19ad77dc4adc36c0349c35.jpg",
                "es": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_LY_14/JPG/es_ES/86cde77549336116fe9f740a581da451.jpg",
                "it": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_LY_14/JPG/it_IT/30cd33ba55ee483b3cae9168904c1a14.jpg",
                "de": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_LY_14/JPG/de_DE/ef6ebbcd432d45a74c1c1a8fefc9c61f.jpg"
            },
            "id": "ALT_CORE_B_LY_14_R2",
            "type": "CHARACTER",
            "manaCost": [
                "3",
                "2"
            ],
            "power": [
                "0",
                "3",
                "2"
            ],
            "rarity": "RARE",
            "faction": "MUNA",
            "cardId": "7f56701c-d25b-47fc-b869-689c04f37cc2"
        },
        {
            "name": "Muna Merchant",
            "image": "https://altered-prod-eu.s3.amazonaws.com/Art/COREKS/CARDS/ALT_CORE_B_MU_23/JPG/en_US/4443b5a12dab44fbb57b98a69ca13179.jpg",
            "images": {
                "en": "https://altered-prod-eu.s3.amazonaws.com/Art/COREKS/CARDS/ALT_CORE_B_MU_23/JPG/en_US/4443b5a12dab44fbb57b98a69ca13179.jpg",
                "fr": "https://altered-prod-eu.s3.amazonaws.com/Art/COREKS/CARDS/ALT_CORE_B_MU_23/JPG/fr_FR/3ff717654808b383eedaea793ecf2ef9.jpg",
                "es": "https://altered-prod-eu.s3.amazonaws.com/Art/COREKS/CARDS/ALT_CORE_B_MU_23/JPG/es_ES/771bbd2dcdabc3524e9f0263306395f9.jpg",
                "it": "https://altered-prod-eu.s3.amazonaws.com/Art/COREKS/CARDS/ALT_CORE_B_MU_23/JPG/it_IT/ba31ee42f7658475eec267d651a8d611.jpg",
                "de": "https://altered-prod-eu.s3.amazonaws.com/Art/COREKS/CARDS/ALT_CORE_B_MU_23/JPG/de_DE/99fcd7b768ba0b432e4f53c3ca702c07.jpg"
            },
            "id": "ALT_COREKS_B_MU_23_R1",
            "type": "CHARACTER",
            "manaCost": [
                "2",
                "2"
            ],
            "power": [
                "3",
                "2",
                "0"
            ],
            "rarity": "RARE",
            "faction": "MUNA",
            "cardId": "bb379fbb-8438-4264-9dd8-0f88b382b42c"
        },
        {
            "name": "Armored Jammer",
            "image": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_28/JPG/en_US/f4c6689be6922539c5a3974f62150070.jpg",
            "images": {
                "en": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_28/JPG/en_US/f4c6689be6922539c5a3974f62150070.jpg",
                "fr": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_28/JPG/fr_FR/03bd700bb7f10bebc511c0eab5a2999c.jpg",
                "es": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_28/JPG/es_ES/95bef214019ee75115b11239f9ee264b.jpg",
                "it": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_28/JPG/it_IT/b866fc08b614297094595fccf6e39366.jpg",
                "de": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_28/JPG/de_DE/69d99fe18e463bc05ef98249441db8b4.jpg"
            },
            "id": "ALT_CORE_B_AX_28_C",
            "type": "PERMANENT",
            "manaCost": [
                "2",
                "2"
            ],
            "power": [
                0,
                0,
                0
            ],
            "rarity": "COMMON",
            "faction": "AXIOM",
            "cardId": "b05ab6cb-d9bc-4f30-971a-14ab2e2a1bf7"
        },
        {
            "name": "Aether Shard",
            "image": "https://altered-prod-eu.s3.amazonaws.com/Art/COREKS/CARDS/ALT_CORE_B_YZ_29/JPG/en_US/6cb044d5ea696195dc3b2748e4ceff30.jpg",
            "images": {
                "en": "https://altered-prod-eu.s3.amazonaws.com/Art/COREKS/CARDS/ALT_CORE_B_YZ_29/JPG/en_US/6cb044d5ea696195dc3b2748e4ceff30.jpg",
                "fr": "https://altered-prod-eu.s3.amazonaws.com/Art/COREKS/CARDS/ALT_CORE_B_YZ_29/JPG/fr_FR/a56302730253eeb2ff47c4d9c7f8283e.jpg",
                "es": "https://altered-prod-eu.s3.amazonaws.com/Art/COREKS/CARDS/ALT_CORE_B_YZ_29/JPG/es_ES/2de4de87b4d9ff03b32bf930c5c2a9c5.jpg",
                "it": "https://altered-prod-eu.s3.amazonaws.com/Art/COREKS/CARDS/ALT_CORE_B_YZ_29/JPG/it_IT/cbb0d6e1dc74498a530b85711b0d5740.jpg",
                "de": "https://altered-prod-eu.s3.amazonaws.com/Art/COREKS/CARDS/ALT_CORE_B_YZ_29/JPG/de_DE/1d6d23364f1a9d1debed9a5b5a8accc9.jpg"
            },
            "id": "ALT_COREKS_B_YZ_29_C",
            "type": "PERMANENT",
            "manaCost": [
                "5",
                "5"
            ],
            "power": [
                0,
                0,
                0
            ],
            "rarity": "COMMON",
            "faction": "YZMIR",
            "cardId": "9b3527f3-8752-45cf-a5c8-ec0a3108eb60"
        },
        {
            "name": "Brassbug Hive",
            "image": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_30/JPG/en_US/c5ba409f8e3260f3232bbe3fc04a3778.jpg",
            "images": {
                "en": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_30/JPG/en_US/c5ba409f8e3260f3232bbe3fc04a3778.jpg",
                "fr": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_30/JPG/fr_FR/560b754321d68197b300d451f4a3ab7f.jpg",
                "es": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_30/JPG/es_ES/42e056e597ca56c64cfb7266ee06e32e.jpg",
                "it": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_30/JPG/it_IT/86afa205fb1014a0eed2bfcb837bebf5.jpg",
                "de": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_30/JPG/de_DE/89a1e16dee0d7502e3869ddde6ddb378.jpg"
            },
            "id": "ALT_CORE_B_AX_30_R2",
            "type": "PERMANENT",
            "manaCost": [
                "5",
                "5"
            ],
            "power": [
                0,
                0,
                0
            ],
            "rarity": "RARE",
            "faction": "YZMIR",
            "cardId": "0487d234-ac0f-48bf-b8a1-f67d45b5f2ce"
        },
        {
            "name": "Tinker Bell",
            "image": "https://altered-prod-eu.s3.amazonaws.com/Art/COREKS/CARDS/ALT_CORE_B_AX_09/JPG/en_US/0816390c0dca32e9dd9daf34d0d99767.jpg",
            "images": {
                "en": "https://altered-prod-eu.s3.amazonaws.com/Art/COREKS/CARDS/ALT_CORE_B_AX_09/JPG/en_US/0816390c0dca32e9dd9daf34d0d99767.jpg",
                "fr": "https://altered-prod-eu.s3.amazonaws.com/Art/COREKS/CARDS/ALT_CORE_B_AX_09/JPG/fr_FR/465c33a29f9989bc08a747e2321fad3f.jpg",
                "es": "https://altered-prod-eu.s3.amazonaws.com/Art/COREKS/CARDS/ALT_CORE_B_AX_09/JPG/es_ES/d4d2b8b6ffae88639e1503989a69c5d0.jpg",
                "it": "https://altered-prod-eu.s3.amazonaws.com/Art/COREKS/CARDS/ALT_CORE_B_AX_09/JPG/it_IT/611fae8eec59d2cf4d58a0c088be1dc7.jpg",
                "de": "https://altered-prod-eu.s3.amazonaws.com/Art/COREKS/CARDS/ALT_CORE_B_AX_09/JPG/de_DE/bab891b62d48eb569ffd384e58c59ab4.jpg"
            },
            "id": "ALT_COREKS_B_AX_09_C",
            "type": "CHARACTER",
            "manaCost": [
                "3",
                "3"
            ],
            "power": [
                "1",
                "3",
                "3"
            ],
            "rarity": "COMMON",
            "faction": "AXIOM",
            "cardId": "c3c0aef4-808c-4258-8dfd-6bb478d44eab"
        },
        {
            "name": "Verdantback",
            "image": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_MU_19/JPG/en_US/a06532b53464436da09986a795147df0.jpg",
            "images": {
                "en": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_MU_19/JPG/en_US/a06532b53464436da09986a795147df0.jpg",
                "fr": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_MU_19/JPG/fr_FR/251af049cb9d5a5a7241ba74957d716e.jpg",
                "es": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_MU_19/JPG/es_ES/cc23d25fcdcaa2414ca95087e31b233f.jpg",
                "it": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_MU_19/JPG/it_IT/d5b5e3676885c9921864da4037a3a83b.jpg",
                "de": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_MU_19/JPG/de_DE/66bd3c72a04d901114346a20d3bb1b7c.jpg"
            },
            "id": "ALT_CORE_B_MU_19_C",
            "type": "CHARACTER",
            "manaCost": [
                "4",
                "4"
            ],
            "power": [
                "5",
                "5",
                "6"
            ],
            "rarity": "COMMON",
            "faction": "MUNA",
            "cardId": "769f40bb-4a36-4fb7-aa55-9a2960937173"
        },
        {
            "name": "Kadigiran Alchemist",
            "image": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_YZ_12/JPG/en_US/4ee47d619331de589a08de4033eef4d6.jpg",
            "images": {
                "en": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_YZ_12/JPG/en_US/4ee47d619331de589a08de4033eef4d6.jpg",
                "fr": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_YZ_12/JPG/fr_FR/183bd912e46b4af4db9df3e924f6401a.jpg",
                "es": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_YZ_12/JPG/es_ES/455fc8a1f167eaca1b9fb6baabda0598.jpg",
                "it": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_YZ_12/JPG/it_IT/b53a1b5e376eb20c4920557301106571.jpg",
                "de": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_YZ_12/JPG/de_DE/adf61c0b0c68fbe95d6f764536467b75.jpg"
            },
            "id": "ALT_CORE_B_YZ_12_C",
            "type": "CHARACTER",
            "manaCost": [
                "3",
                "1"
            ],
            "power": [
                "1",
                "1",
                "1"
            ],
            "rarity": "COMMON",
            "faction": "YZMIR",
            "cardId": "ea5c7f11-cd54-4cda-b27a-a9a5e9b81341"
        },
        {
            "name": "Sakarabru",
            "image": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_YZ_18/JPG/en_US/ef1b1accbd3e9d7274351ee3d5d19b08.jpg",
            "images": {
                "en": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_YZ_18/JPG/en_US/ef1b1accbd3e9d7274351ee3d5d19b08.jpg",
                "fr": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_YZ_18/JPG/fr_FR/2638d99899eb8d222530bd6b6aec9d81.jpg",
                "es": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_YZ_18/JPG/es_ES/c0f09411bfdd8137e41be5b8eba1b126.jpg",
                "it": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_YZ_18/JPG/it_IT/9938f700759d58e6510f65e97136ca92.jpg",
                "de": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_YZ_18/JPG/de_DE/7b8c999fcab3e8fd64836824746eb1f1.jpg"
            },
            "id": "ALT_CORE_B_YZ_18_C",
            "type": "CHARACTER",
            "manaCost": [
                "7",
                "4"
            ],
            "power": [
                "4",
                "4",
                "4"
            ],
            "rarity": "COMMON",
            "faction": "YZMIR",
            "cardId": "75db5c37-d15e-4fc6-b97c-392193a60e94"
        },
        {
            "name": "Kraken's Wrath",
            "image": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_YZ_26/JPG/en_US/c90a0cd673dd791bdc6e26d47e09b7f5.jpg",
            "images": {
                "en": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_YZ_26/JPG/en_US/c90a0cd673dd791bdc6e26d47e09b7f5.jpg",
                "fr": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_YZ_26/JPG/fr_FR/1b53c0561622593ed08e958547087494.jpg",
                "es": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_YZ_26/JPG/es_ES/a9ca64e410d1606437ab49afded81e37.jpg",
                "it": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_YZ_26/JPG/it_IT/1b7e973c5e3fc8154d66f8433a1e5e15.jpg",
                "de": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_YZ_26/JPG/de_DE/7bcee9974a34fcb4000731d4edd34d55.jpg"
            },
            "id": "ALT_CORE_B_YZ_26_C",
            "type": "SPELL",
            "manaCost": [
                "5",
                "5"
            ],
            "power": [
                0,
                0,
                0
            ],
            "rarity": "COMMON",
            "faction": "YZMIR",
            "cardId": "f5462c18-aa7c-442f-b71c-f7cdd354c5b0"
        },
        {
            "name": "Lyra Navigator",
            "image": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_LY_12/JPG/en_US/5d38b42da99343e93bd21d0e1711f32f.jpg",
            "images": {
                "en": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_LY_12/JPG/en_US/5d38b42da99343e93bd21d0e1711f32f.jpg",
                "fr": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_LY_12/JPG/fr_FR/342ac88c0a2743d8867b1cdc9c0b5f14.jpg",
                "es": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_LY_12/JPG/es_ES/f154845708e82d988af0f8296f4d81b0.jpg",
                "it": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_LY_12/JPG/it_IT/0effcbd5dd1c6dbb4a6f6216f5cebe83.jpg",
                "de": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_LY_12/JPG/de_DE/95ab68902b5378754998cbeede1afd5c.jpg"
            },
            "id": "ALT_CORE_B_LY_12_R1",
            "type": "CHARACTER",
            "manaCost": [
                "2",
                "2"
            ],
            "power": [
                "0",
                "0",
                "4"
            ],
            "rarity": "RARE",
            "faction": "LYRA",
            "cardId": "06a03687-4252-462d-966a-f23ac6043614"
        },
        {
            "name": "Bountiful Meadow",
            "image": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_MU_24/JPG/en_US/dfeb000f850c71d76e9c74bd2abe0fce.jpg",
            "images": {
                "en": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_MU_24/JPG/en_US/dfeb000f850c71d76e9c74bd2abe0fce.jpg",
                "fr": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_MU_24/JPG/fr_FR/f279c6da8cabd47f598cba6e9d5497b4.jpg",
                "es": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_MU_24/JPG/es_ES/e4d8b56f06cfbf069b4133c7b97f859e.jpg",
                "it": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_MU_24/JPG/it_IT/ff1c7fa5019125363615f1604fa3f3de.jpg",
                "de": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_MU_24/JPG/de_DE/17e481ca15aecef22fba62959461091d.jpg"
            },
            "id": "ALT_CORE_B_MU_24_R2",
            "type": "PERMANENT",
            "manaCost": [
                "2",
                "2"
            ],
            "power": [
                0,
                0,
                0
            ],
            "rarity": "RARE",
            "faction": "ORDIS",
            "cardId": "53b9d3aa-c6ff-4f06-b156-5ded67797bda"
        },
        {
            "name": "Physical Training",
            "image": "https://altered-prod-eu.s3.amazonaws.com/Art/COREKS/CARDS/ALT_CORE_B_BR_26/JPG/en_US/03f33a4627ff7f0d7822f935ece56b89.jpg",
            "images": {
                "en": "https://altered-prod-eu.s3.amazonaws.com/Art/COREKS/CARDS/ALT_CORE_B_BR_26/JPG/en_US/03f33a4627ff7f0d7822f935ece56b89.jpg",
                "fr": "https://altered-prod-eu.s3.amazonaws.com/Art/COREKS/CARDS/ALT_CORE_B_BR_26/JPG/fr_FR/4efcea74b2b82e6aed099ba80778fe65.jpg",
                "es": "https://altered-prod-eu.s3.amazonaws.com/Art/COREKS/CARDS/ALT_CORE_B_BR_26/JPG/es_ES/c22010ae4473b3ccfa5392460d37963d.jpg",
                "it": "https://altered-prod-eu.s3.amazonaws.com/Art/COREKS/CARDS/ALT_CORE_B_BR_26/JPG/it_IT/12b1267922692f07abf0f841fd22c8f5.jpg",
                "de": "https://altered-prod-eu.s3.amazonaws.com/Art/COREKS/CARDS/ALT_CORE_B_BR_26/JPG/de_DE/6c561883ad7fb6529571d596b62b47f4.jpg"
            },
            "id": "ALT_COREKS_B_BR_26_C",
            "type": "SPELL",
            "manaCost": [
                "2",
                "3"
            ],
            "power": [
                0,
                0,
                0
            ],
            "rarity": "COMMON",
            "faction": "BRAVOS",
            "cardId": "de795201-8d88-4e8d-be13-510a47f9e447"
        },
        {
            "name": "Jeanne d'Arc",
            "image": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_OR_17/JPG/en_US/96c86c0a7e692d912c517191b9b86a09.jpg",
            "images": {
                "en": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_OR_17/JPG/en_US/96c86c0a7e692d912c517191b9b86a09.jpg",
                "fr": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_OR_17/JPG/fr_FR/781fd303a4e9bb5081a2998651ca4c31.jpg",
                "es": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_OR_17/JPG/es_ES/686f7538e27e1207fed9cb145837bf23.jpg",
                "it": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_OR_17/JPG/it_IT/2869bd25f936a2915c8559311592efbe.jpg",
                "de": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_OR_17/JPG/de_DE/9ff2333000ceba6c67fb33eb4783e974.jpg"
            },
            "id": "ALT_CORE_B_OR_17_R2",
            "type": "CHARACTER",
            "manaCost": [
                "5",
                "5"
            ],
            "power": [
                "3",
                "3",
                "3"
            ],
            "rarity": "RARE",
            "faction": "AXIOM",
            "cardId": "accbe98e-3fbc-4231-b999-48a8ca6e7f85"
        },
        {
            "name": "Teija & Nauraa",
            "image": "https://altered-prod-eu.s3.amazonaws.com/Art/COREKS/CARDS/ALT_CORE_B_MU_01/JPG/en_US/bf31c6402a850ce393a17517dab3656e.jpg",
            "images": {
                "en": "https://altered-prod-eu.s3.amazonaws.com/Art/COREKS/CARDS/ALT_CORE_B_MU_01/JPG/en_US/bf31c6402a850ce393a17517dab3656e.jpg",
                "fr": "https://altered-prod-eu.s3.amazonaws.com/Art/COREKS/CARDS/ALT_CORE_B_MU_01/JPG/fr_FR/700c3eb749493ff0ad0f9ad1a38f3aec.jpg",
                "es": "https://altered-prod-eu.s3.amazonaws.com/Art/COREKS/CARDS/ALT_CORE_B_MU_01/JPG/es_ES/3f11de74d0906a3c510aeb8d94a7fc31.jpg",
                "it": "https://altered-prod-eu.s3.amazonaws.com/Art/COREKS/CARDS/ALT_CORE_B_MU_01/JPG/it_IT/f1f47cfd5f561ddf95186e5f7e19f2bb.jpg",
                "de": "https://altered-prod-eu.s3.amazonaws.com/Art/COREKS/CARDS/ALT_CORE_B_MU_01/JPG/de_DE/d9c376d049b4d2b30faf24f2f16e0177.jpg"
            },
            "id": "ALT_COREKS_B_MU_01_C",
            "type": "HERO",
            "manaCost": [
                0,
                0
            ],
            "power": [
                0,
                0,
                0
            ],
            "rarity": "COMMON",
            "faction": "MUNA",
            "cardId": "ee105f76-faa0-4ae9-939b-15cf9b903ead"
        },
        {
            "name": "Verdantback",
            "image": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_MU_19/JPG/en_US/746d0148cb580d260f1f8a924550eeae.jpg",
            "images": {
                "en": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_MU_19/JPG/en_US/746d0148cb580d260f1f8a924550eeae.jpg",
                "fr": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_MU_19/JPG/fr_FR/2a94f3e1f4fe4eb8a5abac5227d5a98e.jpg",
                "es": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_MU_19/JPG/es_ES/da6556b1d582843e7684a69bbf373691.jpg",
                "it": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_MU_19/JPG/it_IT/40f1160c3819aa1be998f80fcc62f848.jpg",
                "de": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_MU_19/JPG/de_DE/09dc078199561f872d4d1ee32553504d.jpg"
            },
            "id": "ALT_CORE_B_MU_19_R1",
            "type": "CHARACTER",
            "manaCost": [
                "3",
                "3"
            ],
            "power": [
                "4",
                "4",
                "5"
            ],
            "rarity": "RARE",
            "faction": "MUNA",
            "cardId": "aa8db49e-3b38-482f-94a1-f48f3cb91418"
        },
        {
            "name": "Axiom Salvager",
            "image": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_08/JPG/en_US/3f97f3f54502ce702524e05aed7774a5.jpg",
            "images": {
                "en": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_08/JPG/en_US/3f97f3f54502ce702524e05aed7774a5.jpg",
                "fr": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_08/JPG/fr_FR/712714b01813f326b256aa70530bccfb.jpg",
                "es": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_08/JPG/es_ES/ec39dd45af979fa3214e00ebf9745c1c.jpg",
                "it": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_08/JPG/it_IT/94cbe3d81a5eb48c8182e81040289894.jpg",
                "de": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_08/JPG/de_DE/496cae17b7d8badf1fcfdc582bb6da69.jpg"
            },
            "id": "ALT_CORE_B_AX_08_C",
            "type": "CHARACTER",
            "manaCost": [
                "1",
                "2"
            ],
            "power": [
                "1",
                "1",
                "1"
            ],
            "rarity": "COMMON",
            "faction": "AXIOM",
            "cardId": "8d0023f5-3f90-4579-a46c-0ccd7c47b6d9"
        },
        {
            "name": "Tinker Bell",
            "image": "https://altered-prod-eu.s3.amazonaws.com/Art/COREKS/CARDS/ALT_CORE_B_AX_09/JPG/en_US/0816390c0dca32e9dd9daf34d0d99767.jpg",
            "images": {
                "en": "https://altered-prod-eu.s3.amazonaws.com/Art/COREKS/CARDS/ALT_CORE_B_AX_09/JPG/en_US/0816390c0dca32e9dd9daf34d0d99767.jpg",
                "fr": "https://altered-prod-eu.s3.amazonaws.com/Art/COREKS/CARDS/ALT_CORE_B_AX_09/JPG/fr_FR/465c33a29f9989bc08a747e2321fad3f.jpg",
                "es": "https://altered-prod-eu.s3.amazonaws.com/Art/COREKS/CARDS/ALT_CORE_B_AX_09/JPG/es_ES/d4d2b8b6ffae88639e1503989a69c5d0.jpg",
                "it": "https://altered-prod-eu.s3.amazonaws.com/Art/COREKS/CARDS/ALT_CORE_B_AX_09/JPG/it_IT/611fae8eec59d2cf4d58a0c088be1dc7.jpg",
                "de": "https://altered-prod-eu.s3.amazonaws.com/Art/COREKS/CARDS/ALT_CORE_B_AX_09/JPG/de_DE/bab891b62d48eb569ffd384e58c59ab4.jpg"
            },
            "id": "ALT_COREKS_B_AX_09_C",
            "type": "CHARACTER",
            "manaCost": [
                "3",
                "3"
            ],
            "power": [
                "1",
                "3",
                "3"
            ],
            "rarity": "COMMON",
            "faction": "AXIOM",
            "cardId": "c3c0aef4-808c-4258-8dfd-6bb478d44eabfff"
        },
        {
            "name": "Bountiful Meadow",
            "image": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_MU_24/JPG/en_US/212f1fe5168b60abb903a1285bef398f.jpg",
            "images": {
                "en": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_MU_24/JPG/en_US/212f1fe5168b60abb903a1285bef398f.jpg",
                "fr": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_MU_24/JPG/fr_FR/c003dbf68b31be32ee923cc2ac24726b.jpg",
                "es": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_MU_24/JPG/es_ES/8d9e4844b5356a09881d3bd02a2951bb.jpg",
                "it": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_MU_24/JPG/it_IT/db4ec5adfa2f6858dc7fdd8cc61faed7.jpg",
                "de": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_MU_24/JPG/de_DE/aa03bc4b80b6695cf7140790042e6156.jpg"
            },
            "id": "ALT_CORE_B_MU_24_C",
            "type": "PERMANENT",
            "manaCost": [
                "2",
                "2"
            ],
            "power": [
                0,
                0,
                0
            ],
            "rarity": "COMMON",
            "faction": "MUNA",
            "cardId": "bca8b1f8-2053-4c7f-bd45-b0a2dea84ac9"
        },
        {
            "name": "Amelia Earhart",
            "image": "https://altered-prod-eu.s3.amazonaws.com/Art/COREKS/CARDS/ALT_CORE_B_AX_11/JPG/en_US/7fbfb0907d9c043afaec53c291205288.jpg",
            "images": {
                "en": "https://altered-prod-eu.s3.amazonaws.com/Art/COREKS/CARDS/ALT_CORE_B_AX_11/JPG/en_US/7fbfb0907d9c043afaec53c291205288.jpg",
                "fr": "https://altered-prod-eu.s3.amazonaws.com/Art/COREKS/CARDS/ALT_CORE_B_AX_11/JPG/fr_FR/b7a48ed85362bb224801a3781cd21b47.jpg",
                "es": "https://altered-prod-eu.s3.amazonaws.com/Art/COREKS/CARDS/ALT_CORE_B_AX_11/JPG/es_ES/3bc76c85eb07264e82e3258aa3fe603b.jpg",
                "it": "https://altered-prod-eu.s3.amazonaws.com/Art/COREKS/CARDS/ALT_CORE_B_AX_11/JPG/it_IT/1f744afc696f2093cc56af859cb0f503.jpg",
                "de": "https://altered-prod-eu.s3.amazonaws.com/Art/COREKS/CARDS/ALT_CORE_B_AX_11/JPG/de_DE/d988a19bb6d531403a7a026856e0e5d9.jpg"
            },
            "id": "ALT_COREKS_B_AX_11_C",
            "type": "CHARACTER",
            "manaCost": [
                "3",
                "1"
            ],
            "power": [
                "2",
                "2",
                "2"
            ],
            "rarity": "COMMON",
            "faction": "AXIOM",
            "cardId": "f3a9466c-3beb-41ef-be99-6d417b5669e4"
        },
        {
            "name": "Coniferal Coneman",
            "image": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_MU_20/JPG/en_US/c1ebb4c29bf5cbb34325cd0d14030d7c.jpg",
            "images": {
                "en": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_MU_20/JPG/en_US/c1ebb4c29bf5cbb34325cd0d14030d7c.jpg",
                "fr": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_MU_20/JPG/fr_FR/2944f873d6499eea1e1c446fe37def58.jpg",
                "es": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_MU_20/JPG/es_ES/45f442b743aff385d59ed795eef4df7c.jpg",
                "it": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_MU_20/JPG/it_IT/265d80f6a45319ecab1ccb3fd24593f5.jpg",
                "de": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_MU_20/JPG/de_DE/45a0f32d44672219ff5c7bc44e70c494.jpg"
            },
            "id": "ALT_CORE_B_MU_20_C",
            "type": "CHARACTER",
            "manaCost": [
                "5",
                "5"
            ],
            "power": [
                "3",
                "3",
                "3"
            ],
            "rarity": "COMMON",
            "faction": "MUNA",
            "cardId": "019f8de6-15d9-42a2-81b5-8b262dbe3caa"
        },
        {
            "name": "Tooth Fairy",
            "image": "https://altered-prod-eu.s3.amazonaws.com/Art/COREKS/CARDS/ALT_CORE_B_YZ_06/JPG/en_US/78b6a6b3ff1bbae9eb118ac08d94dd06.jpg",
            "images": {
                "en": "https://altered-prod-eu.s3.amazonaws.com/Art/COREKS/CARDS/ALT_CORE_B_YZ_06/JPG/en_US/78b6a6b3ff1bbae9eb118ac08d94dd06.jpg",
                "fr": "https://altered-prod-eu.s3.amazonaws.com/Art/COREKS/CARDS/ALT_CORE_B_YZ_06/JPG/fr_FR/a0f389767728ebacf29ac5ea2bf34684.jpg",
                "es": "https://altered-prod-eu.s3.amazonaws.com/Art/COREKS/CARDS/ALT_CORE_B_YZ_06/JPG/es_ES/87833762b806ce3ffcdd09b49eab1975.jpg",
                "it": "https://altered-prod-eu.s3.amazonaws.com/Art/COREKS/CARDS/ALT_CORE_B_YZ_06/JPG/it_IT/abd962188862449d1f34cad7945cd5a2.jpg",
                "de": "https://altered-prod-eu.s3.amazonaws.com/Art/COREKS/CARDS/ALT_CORE_B_YZ_06/JPG/de_DE/499725c4d095ef4d640156b69589b6a9.jpg"
            },
            "id": "ALT_COREKS_B_YZ_06_C",
            "type": "CHARACTER",
            "manaCost": [
                "2",
                "2"
            ],
            "power": [
                "1",
                "1",
                "1"
            ],
            "rarity": "COMMON",
            "faction": "YZMIR",
            "cardId": "bd253ad4-90ac-464e-8b75-75b2301e7911"
        },
        {
            "name": "Subhash & Marmo",
            "image": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_03/JPG/en_US/05ba21398ad2dce1eb30beccc957a1c2.jpg",
            "images": {
                "en": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_03/JPG/en_US/05ba21398ad2dce1eb30beccc957a1c2.jpg",
                "fr": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_03/JPG/fr_FR/685ac001d7271fbefaefbd13eee58c21.jpg",
                "es": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_03/JPG/es_ES/4cf8d7b23e62e5db6004583a896bed7c.jpg",
                "it": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_03/JPG/it_IT/481a2c5e43168b05bf3712dec096e03b.jpg",
                "de": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_03/JPG/de_DE/b6ea03896dc67b06c71733b06a11ea73.jpg"
            },
            "id": "ALT_CORE_B_AX_03_C",
            "type": "HERO",
            "manaCost": [
                0,
                0
            ],
            "power": [
                0,
                0,
                0
            ],
            "rarity": "COMMON",
            "faction": "AXIOM",
            "cardId": "acfcbd50-4be3-4a3d-bed2-666427c171bc"
        },
        {
            "name": "Kelonic Generator",
            "image": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_27/JPG/en_US/ea759aa0efcf6595d085a114a6b2b6b8.jpg",
            "images": {
                "en": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_27/JPG/en_US/ea759aa0efcf6595d085a114a6b2b6b8.jpg",
                "fr": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_27/JPG/fr_FR/d79b8d33898bb8852efe9c24ce443e62.jpg",
                "es": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_27/JPG/es_ES/7eb80e39d0a251eeba995973d429542c.jpg",
                "it": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_27/JPG/it_IT/1f9d93248857d3a936a47986df028414.jpg",
                "de": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_27/JPG/de_DE/cc374542ed16490a69d4a75de771341f.jpg"
            },
            "id": "ALT_CORE_B_AX_27_C",
            "type": "PERMANENT",
            "manaCost": [
                "3",
                "3"
            ],
            "power": [
                0,
                0,
                0
            ],
            "rarity": "COMMON",
            "faction": "AXIOM",
            "cardId": "dab42af2-341e-4389-b3d6-88c662dae11c"
        },
        {
            "name": "The Foundry, Axiom Bastion",
            "image": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_29/JPG/en_US/914a90e2044f9af8e6b2d8a817408dbd.jpg",
            "images": {
                "en": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_29/JPG/en_US/914a90e2044f9af8e6b2d8a817408dbd.jpg",
                "fr": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_29/JPG/fr_FR/726720d0dd11708cd58c6284aff38e74.jpg",
                "es": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_29/JPG/es_ES/2e9661021bcef52723b52b6159872dc5.jpg",
                "it": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_29/JPG/it_IT/e57752105698c6a01830469c626f85c2.jpg",
                "de": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_29/JPG/de_DE/929db21c90da387b107b5452b19145de.jpg"
            },
            "id": "ALT_CORE_B_AX_29_C",
            "type": "PERMANENT",
            "manaCost": [
                "3",
                "3"
            ],
            "power": [
                0,
                0,
                0
            ],
            "rarity": "COMMON",
            "faction": "AXIOM",
            "cardId": "e2919ef7-d4ba-4ece-854e-cc79c239be3c"
        },
        {
            "name": "Son of Yggdrasil",
            "image": "https://altered-prod-eu.s3.amazonaws.com/Art/COREKS/CARDS/ALT_CORE_B_MU_21/JPG/en_US/dccefe9800d68a7556c028b1b67d47f2.jpg",
            "images": {
                "en": "https://altered-prod-eu.s3.amazonaws.com/Art/COREKS/CARDS/ALT_CORE_B_MU_21/JPG/en_US/dccefe9800d68a7556c028b1b67d47f2.jpg",
                "fr": "https://altered-prod-eu.s3.amazonaws.com/Art/COREKS/CARDS/ALT_CORE_B_MU_21/JPG/fr_FR/1fedd939bb6d1ba8365157dd0627f2da.jpg",
                "es": "https://altered-prod-eu.s3.amazonaws.com/Art/COREKS/CARDS/ALT_CORE_B_MU_21/JPG/es_ES/11512dbb20f5036ec84e0d91511e4d1d.jpg",
                "it": "https://altered-prod-eu.s3.amazonaws.com/Art/COREKS/CARDS/ALT_CORE_B_MU_21/JPG/it_IT/8d8408eda8bc6bd0041bb1c607dbabde.jpg",
                "de": "https://altered-prod-eu.s3.amazonaws.com/Art/COREKS/CARDS/ALT_CORE_B_MU_21/JPG/de_DE/467d029d70801f829d552f37e9fe2285.jpg"
            },
            "id": "ALT_COREKS_B_MU_21_C",
            "type": "CHARACTER",
            "manaCost": [
                "6",
                "6"
            ],
            "power": [
                "6",
                "3",
                "3"
            ],
            "rarity": "COMMON",
            "faction": "MUNA",
            "cardId": "9f26bb7d-eaed-45d6-8aef-a449f84ecc83"
        },
        {
            "name": "Mechanical Training",
            "image": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_22/JPG/en_US/6de108a30b501009e8ffdb9da84b4494.jpg",
            "images": {
                "en": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_22/JPG/en_US/6de108a30b501009e8ffdb9da84b4494.jpg",
                "fr": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_22/JPG/fr_FR/6376184165eb0c586cb1654a1a7c55b6.jpg",
                "es": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_22/JPG/es_ES/954708e0eac9dc7cbb3a257c62e93d4b.jpg",
                "it": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_22/JPG/it_IT/b23ad2068328ed17492ce1c726a67d8f.jpg",
                "de": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_22/JPG/de_DE/845afed041181a0b0b546b666a6c2758.jpg"
            },
            "id": "ALT_CORE_B_AX_22_C",
            "type": "SPELL",
            "manaCost": [
                "1",
                "2"
            ],
            "power": [
                0,
                0,
                0
            ],
            "rarity": "COMMON",
            "faction": "AXIOM",
            "cardId": "0e9122a2-1ce2-41de-8076-18313b6fbd87"
        },
        {
            "name": "Thoth",
            "image": "https://altered-prod-eu.s3.amazonaws.com/Art/COREKS/CARDS/ALT_CORE_B_OR_22/JPG/en_US/fdb713b3062fdcc5bc72d56fcee3bf20.jpg",
            "images": {
                "en": "https://altered-prod-eu.s3.amazonaws.com/Art/COREKS/CARDS/ALT_CORE_B_OR_22/JPG/en_US/fdb713b3062fdcc5bc72d56fcee3bf20.jpg",
                "fr": "https://altered-prod-eu.s3.amazonaws.com/Art/COREKS/CARDS/ALT_CORE_B_OR_22/JPG/fr_FR/412d6f3ce2a43560924df75414f51080.jpg",
                "es": "https://altered-prod-eu.s3.amazonaws.com/Art/COREKS/CARDS/ALT_CORE_B_OR_22/JPG/es_ES/ad79375db9854a0cb3b78211f1db7d03.jpg",
                "it": "https://altered-prod-eu.s3.amazonaws.com/Art/COREKS/CARDS/ALT_CORE_B_OR_22/JPG/it_IT/24831d4e707acceca88f3e8b99fa01e5.jpg",
                "de": "https://altered-prod-eu.s3.amazonaws.com/Art/COREKS/CARDS/ALT_CORE_B_OR_22/JPG/de_DE/ca751b682279265cfe5988bf4ee91da4.jpg"
            },
            "id": "ALT_COREKS_B_OR_22_R1",
            "type": "CHARACTER",
            "manaCost": [
                "3",
                "3"
            ],
            "power": [
                "3",
                "3",
                "2"
            ],
            "rarity": "RARE",
            "faction": "ORDIS",
            "cardId": "9cf8862d-4310-4bd3-85f0-77abd376d802"
        },
        {
            "name": "Quetzalcóatl",
            "image": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_OR_18/JPG/en_US/3f8a15bc95d89efd6cd4f5fc93349c29.jpg",
            "images": {
                "en": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_OR_18/JPG/en_US/3f8a15bc95d89efd6cd4f5fc93349c29.jpg",
                "fr": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_OR_18/JPG/fr_FR/d72e9fb99bf49d44675cbc1e8d88f5c4.jpg",
                "es": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_OR_18/JPG/es_ES/88983d6078a2b4a1ede79206c243d759.jpg",
                "it": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_OR_18/JPG/it_IT/b8e7150346779d74a4631ef65ebd8d57.jpg",
                "de": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_OR_18/JPG/de_DE/8fbcbec65f0b99fc06212f20c32e5d59.jpg"
            },
            "id": "ALT_CORE_B_OR_18_R1",
            "type": "CHARACTER",
            "manaCost": [
                "3",
                "3"
            ],
            "power": [
                "3",
                "3",
                "3"
            ],
            "rarity": "RARE",
            "faction": "ORDIS",
            "cardId": "ad3350ff-68c2-470e-a091-ee30bfae9d90"
        },
        {
            "name": "Studious Disciple",
            "image": "https://altered-prod-eu.s3.amazonaws.com/Art/COREKS/CARDS/ALT_CORE_B_YZ_04/JPG/en_US/85ec85b1db1b0a4a2d216d3df2c590f8.jpg",
            "images": {
                "en": "https://altered-prod-eu.s3.amazonaws.com/Art/COREKS/CARDS/ALT_CORE_B_YZ_04/JPG/en_US/85ec85b1db1b0a4a2d216d3df2c590f8.jpg",
                "fr": "https://altered-prod-eu.s3.amazonaws.com/Art/COREKS/CARDS/ALT_CORE_B_YZ_04/JPG/fr_FR/1af875c21e191dce278fa1c27e3d63ea.jpg",
                "es": "https://altered-prod-eu.s3.amazonaws.com/Art/COREKS/CARDS/ALT_CORE_B_YZ_04/JPG/es_ES/eee373ed3b6081d944a7a942480e2468.jpg",
                "it": "https://altered-prod-eu.s3.amazonaws.com/Art/COREKS/CARDS/ALT_CORE_B_YZ_04/JPG/it_IT/fd4cf5dc5c4f7159c0a23c6a6b26fb55.jpg",
                "de": "https://altered-prod-eu.s3.amazonaws.com/Art/COREKS/CARDS/ALT_CORE_B_YZ_04/JPG/de_DE/c6ed1009b3ecf37c93c792e8e5423cac.jpg"
            },
            "id": "ALT_COREKS_B_YZ_04_R2",
            "type": "CHARACTER",
            "manaCost": [
                "1",
                "1"
            ],
            "power": [
                "0",
                "1",
                "1"
            ],
            "rarity": "RARE",
            "faction": "LYRA",
            "cardId": "f586bf68-0c76-4ddf-bccb-9d60e880dab2"
        },
        {
            "name": "Ordis Spy",
            "image": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_OR_14/JPG/en_US/ed70660d417244f825deb5292dec36c2.jpg",
            "images": {
                "en": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_OR_14/JPG/en_US/ed70660d417244f825deb5292dec36c2.jpg",
                "fr": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_OR_14/JPG/fr_FR/1c474d8247d1ee8946e3184250be6e43.jpg",
                "es": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_OR_14/JPG/es_ES/febc008a8353b84e1226b9eb7add2ae1.jpg",
                "it": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_OR_14/JPG/it_IT/658fbf07c988298d91015a860a057ddc.jpg",
                "de": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_OR_14/JPG/de_DE/b577bc45b2423087dc32bd14689a6af2.jpg"
            },
            "id": "ALT_CORE_B_OR_14_C",
            "type": "CHARACTER",
            "manaCost": [
                "3",
                "2"
            ],
            "power": [
                "2",
                "2",
                "2"
            ],
            "rarity": "COMMON",
            "faction": "ORDIS",
            "cardId": "28c7e9dc-cf23-443c-af06-c101ba978882"
        }
    ]
    const [cardsPicked, setCardsPicked] = useState([])


    useEffect(() => {
        const unload = async (e) => {
            e.preventDefault()
            return (e.returnValue = 'Are you sure you want to close?')
        }
        window.addEventListener('beforeunload', unload)
        return () => {
            window.removeEventListener(
                'beforeunload',
                unload,
            );
        };
    }, [])

    if (!isDraftComplet && props.turnOrder) {
        return (<DraftPanel isHost={props.isHost} globalData={props.globalData} playersData={props.playersData} sendDataToOpponents={props.sendDataToOpponents} playerId={props.playerId} turnOrder={props.turnOrder} cardsPicked={cardsPicked} setCardsPicked={setCardsPicked} setIsDraftComplete={setIsDraftComplete} />)
    }
    return (<DraftDeckBuild cardsPicked={cardsPicked} />)
}

function DraftDeckBuild(props) {
    const { t, i18n } = useLanguageContext()
    const [deckList, setDeckList] = useState([])
    const [rubbish, setRubbish] = useState([])
    const [cardDragged, setCardDragged] = useState(false)
    const [cardZoomed, setCardZoomed] = useState(false)
    const scrollRef = useHorizontalScroll();
    const factions = ["AXIOM", "BRAVOS", "LYRA", "MUNA", "ORDIS", "YZMIR"]
    const [deckFactions, setDeckFactions] = useState({
        AXIOM: false,
        BRAVOS: false,
        LYRA: false,
        MUNA: false,
        ORDIS: false,
        YZMIR: false,
        count: 0
    })
    const [factionFilter, setFactionFilter] = useState(false)
    const [typeFilter, setTypeFilter] = useState(false)
    const [showExportPanel, setShowExportPanel] = useState(false)
    const [deckListExport, setDeckListExport] = useState("")
    const [heroCount, setHeroCount] = useState(0)

    useLayoutEffect(() => {
        let newFactions = {
            AXIOM: false,
            BRAVOS: false,
            LYRA: false,
            MUNA: false,
            ORDIS: false,
            YZMIR: false,
            count: 0
        }
        deckList.forEach((c) => {
            if (!newFactions[c.card.faction]) {
                newFactions[c.card.faction] = true
                newFactions.count += 1
            }
        })
        setDeckFactions(newFactions)

        let tmp = []
        deckList.forEach((c) => {
            tmp.push({ count: c.count, cardRef: c.card.id })
        })
        let str = ""
        tmp.forEach((c) => {
            str += c.count + " " + c.cardRef + "\n"
        })
        let manaConvergenceCount = 30 - deckList.length
        if (manaConvergenceCount > 0) {
            str += manaConvergenceCount + " ALT_CORE_B_NE_1_C\n"
        }
        setDeckListExport(str)

        let hCount = 0
        deckList.forEach((c) => {
            if (c.card.type == "HERO") {
                hCount += c.count
            }
        })
        setHeroCount(hCount)
    }, [deckList])

    useEffect(() => {
        // Keep in the deck the 3 main factions
        let factionsCount = {
            BRAVOS: 0,
            AXIOM: 0,
            MUNA: 0,
            LYRA: 0,
            ORDIS: 0,
            YZMIR: 0
        }
        props.cardsPicked.forEach((c) => {
            factionsCount[c.faction] += 1
        })
        let sortable = [];
        for (var f in factionsCount) {
            sortable.push([f, factionsCount[f]]);
        }
        sortable.sort(function (a, b) {
            return a[1] - b[1];
        });
        let top3Factions = [sortable[5][0], sortable[4][0], sortable[3][0]]

        let tmpRubbish = []
        let tmpDecklist = []
        props.cardsPicked.forEach((c) => {
            if (top3Factions.includes(c.faction)) {
                addToCardArray(tmpDecklist, c)
            } else {
                addToCardArray(tmpRubbish, c)
            }
        })

        setNewRubbish(tmpRubbish)
        updateDeckList(tmpDecklist)
    }, [])

    const addToCardArray = (arr, card) => {
        let index = arr.findIndex(e => e.card.id == card.id)
        if (index >= 0) {
            arr[index].count += 1
        } else {
            let c = { ...card }
            c.cardId = uuidv4()
            arr.push({ count: 1, card: c })
        }
    }

    const updateDeckList = (newDeckList) => {
        let tmp = [...newDeckList]
        tmp.sort((a, b) => a.card.name.localeCompare(b.card.name));
        setDeckList(tmp)
    }

    const dropCard = (destination) => {
        if (!cardDragged) { return }
        if (destination != cardDragged.source) {
            if (destination == "rubbish") {
                let tmp = [...rubbish]
                addToCardArray(tmp, cardDragged.card)
                setNewRubbish(tmp)

                let tmp2 = [...deckList]
                let index = tmp2.findIndex(e => e.card.id == cardDragged.card.id)
                if (index >= 0) {
                    tmp2[index].count -= 1
                    if (tmp2[index].count <= 0) {
                        tmp2.splice(index, 1)
                    }
                }
                updateDeckList(tmp2)
            } else if (destination == "decklist") {
                let tmp = [...deckList]
                addToCardArray(tmp, cardDragged.card)
                updateDeckList(tmp)

                let tmp2 = [...rubbish]
                let index = tmp2.findIndex(e => e.card.id == cardDragged.card.id)
                if (index >= 0) {
                    tmp2[index].count -= 1
                    if (tmp2[index].count <= 0) {
                        tmp2.splice(index, 1)
                    }
                }
                setNewRubbish(tmp2)
            }
        }

        setCardDragged(false)
    }

    const setNewRubbish = (newRubbish) => {
        newRubbish.sort((a, b) => a.card.name.localeCompare(b.card.name));
        setRubbish(newRubbish)
    }

    return (<div class={"draft-panel"} >
        <div class="background-wrapper" onPointerUp={() => setCardDragged(false)}>
            <div class="blurred-background"></div>
        </div>
        <Navigation />

        <div class={"deck-build" + (cardDragged ? " waiting-selection" : "") + (factionFilter ? " faction-filter " + factionFilter.join(" ") : "") + (typeFilter ? " type-filter " + typeFilter.join(" ") : "")} >
            <div class="deck-rubbish" onPointerUp={() => dropCard("rubbish")} >
                <h2 class="drop-info-message">{t("draft.remove")}</h2>
                <div ref={scrollRef} class="side-scroll h-100 d-flex flex-row align-items-center">
                    {
                        rubbish.map((c, i) => {
                            return (<DraftCard card={c} cardDragged={cardDragged}
                                key={c.card.cardId}
                                setCardZoomed={setCardZoomed}
                                onpointerdown={() => {
                                    setCardDragged({ source: "rubbish", card: c.card, index: i })
                                }} />)
                        })
                    }
                </div>
            </div>

            <Resizable className="light-shadowed">
                <div class={"deck-list position-relative"} onPointerUp={() => dropCard("decklist")} >
                    <div class="blurred-background"></div>
                    <h2 class="drop-info-message">{t("draft.add")}</h2>
                    <div class="cards-list-wrapper hide-scrollbar w-100 h-100 overflow-y-scroll">
                        <div class="cards-list row row-cols-7">
                            {[0, 1, 2, 3, 4, 5, 6].map((v) => {
                                if (v == 6) {
                                    return (<div class="col">
                                        {deckList.map((c, i) => {
                                            if (c.card.manaCost[0] >= v) {
                                                return (<DraftCard card={c} cardDragged={cardDragged}
                                                    key={c.card.cardId}
                                                    setCardZoomed={setCardZoomed}
                                                    onpointerdown={() => {
                                                        setCardDragged({ source: "decklist", card: c.card, index: i })
                                                    }} />)
                                            }
                                        })}
                                    </div>)
                                } else {
                                    return (<div class="col">
                                        {deckList.map((c, i) => {
                                            if (c.card.manaCost[0] == v) {
                                                return (<DraftCard card={c} cardDragged={cardDragged}
                                                    key={c.card.cardId}
                                                    setCardZoomed={setCardZoomed}
                                                    onpointerdown={() => {
                                                        setCardDragged({ source: "decklist", card: c.card, index: i })
                                                    }} />)
                                            }
                                        })}
                                    </div>)
                                }
                            })}
                        </div>
                    </div>
                    <div class="box-blurred w-100 deck-info position-absolute d-flex flex-row align-items-center top-0 left-0">
                        <p class="fw-bold">{t("draft.your_deck")}:</p>
                        <p class="fw-bold">{deckList.length}/30</p>
                        {deckList.length < 30 && (<p class="">+ {30 - deckList.length} mana</p>)}
                        <div class="banners d-flex flex-row align-items-center ms-5">
                            {factions.map((f) => {
                                return (<img class={(deckFactions[f] ? " present" : " absent") + (factionFilter && factionFilter.includes(f) ? " is-filter" : "")} src={require('./Assets/' + f + '-faction-banner.png')}
                                    onClick={() => {
                                        if (!factionFilter) { setFactionFilter([f]); return }
                                        let index = factionFilter.findIndex(x => x === f)
                                        if (index >= 0) {
                                            let n = factionFilter.filter(x => x !== f)
                                            if (n.length == 0) {
                                                setFactionFilter(false)
                                            } else {
                                                setFactionFilter(n)
                                            }
                                        } else {
                                            let n = [...factionFilter]
                                            n.push(f)
                                            setFactionFilter(n)
                                        }
                                    }}
                                />)
                            })}
                        </div>
                        <div class="d-flex flex-row align-items-center types-filters">
                            {["HERO", "CHARACTER", "SPELL", "PERMANENT"].map((ty) => {
                                return (<Button class="tertiary" selected={typeFilter && typeFilter.includes(ty)} onClick={() => {
                                    if (!typeFilter) { setTypeFilter([ty]); return }
                                    let index = typeFilter.findIndex(x => x === ty)
                                    if (index >= 0) {
                                        let n = typeFilter.filter(x => x !== ty)
                                        if (n.length == 0) {
                                            setTypeFilter(false)
                                        } else {
                                            setTypeFilter(n)
                                        }
                                    } else {
                                        let n = [...typeFilter]
                                        n.push(ty)
                                        setTypeFilter(n)
                                    }
                                }}>
                                    {t("commons.types." + ty)} ({deckList.filter((c) => c.card.type == ty).length})
                                </Button>)
                            })}
                        </div>
                        <div class="ms-auto d-flex flex-row">
                            {deckFactions.count <= 3 && heroCount <= 1 && (<Button onClick={() => setShowExportPanel(true)}>{t("draft.end")}</Button>)}
                            {deckFactions.count > 3 && (<p class="ms-auto">{t("draft.factions_limit")}</p>)}
                            {heroCount > 1 && (<p>{t("draft.hero_limit")}</p>)}
                        </div>
                    </div>
                </div>
            </Resizable>
        </div>
        <div class="participants d-flex flex-row">
            <div class="nav-items d-flex flex-row">
                <LanguageSelect />
                <DarkMode />
            </div>
        </div>
        <div class={cardDragged ? "opacity-0" : ""}>
            <ZoomedCard zoomedVirtualCard={cardZoomed} widthVh={35} />
        </div>
        <DraggedCard cardDragged={cardDragged} />
        {showExportPanel && (<FullScreenPanel title={t("game.menu.button_leave.pannel_title")} component={
            <ExportPanelContent deckListExport={deckListExport} deckList={deckList} />
        } hidePanel={() => setShowExportPanel(false)} />)}
    </div >)
}

function ExportPanelContent(props) {
    const { t, i18n } = useLanguageContext()
    const [saveFeedback, setSaveFeedback] = useState(false)

    const saveDeckList = () => {

        let deckList = []
        for (let i = 0; i < props.deckList.length; i++) {
            for (let j = 0; j < props.deckList[i].count; j++) {
                console.log(props.deckList[i].count)
                let c = { ...props.deckList[i].card }
                c.cardId = uuidv4() + "i"
                deckList.push(c)
            }
        }
        if (deckList.length < 30) {
            let missingCards = 30 - deckList.length
            let mana = getCardFromCardData(cardsData["ALT_CORE_B_NE_1_C"], i18n.language)
            for (let i = 0; i < missingCards; i++) {
                let c = { ...mana }
                c.cardId = uuidv4() + "mana" + i
                deckList.push(c)
            }
        }

        let newData = JSON.parse(JSON.stringify(virtualBoardDefault))
        newData.deckList = [...deckList]

        let item = window.localStorage.getItem("board-data")
        if (item) {
            let oldData = JSON.parse(item)
            console.log("ALREADY DATA", oldData)
            newData = { ...oldData }
            newData.deckList = [...deckList]
            newData.source = "deck-tester"
        }

        window.localStorage.setItem("board-data", JSON.stringify(newData));
    }

    return (<div class="d-flex flex-column">
        <div class="row">
            <div class="col-6 d-flex flex-column">
                <h2 class="mb-4">Decklist</h2>
                <div class="d-flex flex-column deck-list-export">
                    <p class="m-0">{props.deckListExport}</p>
                </div>
            </div>
            <div class="col-6 d-flex flex-column justify-content-start">
                <h2 class="mb-4">Play</h2>
                <Button class="my-4" onClick={() => {
                    saveDeckList();
                    setSaveFeedback(true)
                    setTimeout(() => {
                        setSaveFeedback(false)
                    }, 2000);
                }}>{saveFeedback ? "Saved !" : "Save deck for Exalts Table"}</Button>
                <p>To play with your deck, press the button above, then <a href="/play" target="_blank">find or create a lobby</a> in the game mode of your choice. Choose "Virtual" and your deck will be there.</p>
                <p>Keep this tab open to make changes to your deck. Press the button above again to update the decklist. Don't be afraid to make changes while playing, your deck is only gonna be updated at the start of a new round.</p>
            </div>
        </div>
    </div>)
}

function DraftCard(props) {
    const { i18n } = useLanguageContext()
    const [isDragging, setIsDragging] = useState(false)

    useEffect(() => {
        if (!props.cardDragged) {
            setIsDragging(false)
        }
    }, [props.cardDragged])

    return (<CSSTransition key={props.card.card.cardId} timeout={700} classNames="item">
        <div class={"card-picked animated-glow " + (isDragging ? " opacity-0" : "") + (" " + props.card.card.faction + " " + props.card.card.type) + (props.className ? " " + props.className : "") + (props.card.count > 1 ? " stacked" : "")}>
            <div
                onPointerDown={() => {
                    setIsDragging(true)
                    props.onpointerdown()
                }}
                onPointerUp={() => setIsDragging(false)}
                onMouseEnter={() => props.setCardZoomed(props.card.card)}
                onMouseLeave={() => props.setCardZoomed(false)}>
                <ImageMemo src={props.card.images ? props.card.images[i18n.language] : props.card.card.image} />
                {props.card.count > 1 && (<h3 class="position-absolute draft-card-count">x{props.card.count}</h3>)}
            </div>
        </div>
    </CSSTransition>)
}

function DraftPanel(props) {
    const { t, i18n } = useLanguageContext()
    const [isOpening, setIsOpening] = useState(false)
    const [currentPack, setCurrentPack] = useState([])
    const [remainingBoosters, setRemainingBoosters] = useState(4)
    const [switchingAnimationProgress, setSwitchingAnimationProgress] = useState(false)
    const [previousPlayerId, setPreviousPlayerId] = useState(0)
    const [isAllReady, setIsAllReady] = useState(false)
    const [showCardsPickedPanel, setShowCardsPickedPanel] = useState(false)

    const heroesRef = useRef([])
    const commonsRef = useRef({
        AXIOM: [],
        BRAVOS: [],
        MUNA: [],
        LYRA: [],
        ORDIS: [],
        YZMIR: []
    })
    const raresRef = useRef([])
    const currentStatusRef = useRef(false)
    const [cardsPickedInOrder, setCardsPickedInOrder] = useState([])

    useEffect(() => {
        let myIndex = props.turnOrder.findIndex((e) => e == props.playerId)
        if (myIndex == 0) {
            setPreviousPlayerId(props.turnOrder[props.turnOrder.length - 1])
        } else {
            setPreviousPlayerId(props.turnOrder[myIndex - 1])
        }

        // ini
        if (heroesRef.current.length == 0) {
            console.log("INI LIST")
            var keys = Object.keys(cardsData);
            keys.forEach((k) => {
                let c = cardsData[k]
                if (!c.id.includes("KS")) {
                    if (c.type == "HERO") {
                        heroesRef.current.push(getCardFromCardData(c, i18n.language))
                    } else if (c.rarity == "RARE") {
                        raresRef.current.push(getCardFromCardData(c, i18n.language))
                    } else if (c.rarity == "COMMON") {
                        let com = getCardFromCardData(c, i18n.language)
                        if (com.faction != "NONE" && com.type != "TOKEN") {
                            commonsRef.current[com.faction].push(com)
                        }
                    }
                }
            })
        }

        if (!props.isHost) {
            setTimeout(() => {
                stayinAlive()
            }, 2000)
        }

    }, [])

    const stayinAlive = () => {
        if (currentStatusRef.current) {
            let data = { ...currentStatusRef.current }
            data.currentPack = [...currentStatusRef.current.currentPack]
            props.sendDataToOpponents({ player: { ...data } })
        }

        setTimeout(() => {
            stayinAlive()
        }, 2000)
    }

    useEffect(() => {
        if (props.isHost) {
            console.log(props.playersData)
            if (isAllReady) { return }
            let allReady = true
            for (let i = 0; i < props.turnOrder.length; i++) {
                if (!props.playersData[props.turnOrder[i]].isReady) {
                    allReady = false
                }
            }
            setIsAllReady(allReady)
        }
    }, [props.playersData, props.playersData[props.playerId]])

    useEffect(() => {
        if (isAllReady && props.isHost) {
            let playersPack = {}
            // Pour chaque joueur, je met à jour le pack
            // Je les envoies sur global

            Object.entries(props.playersData).forEach(([k, v]) => {
                playersPack[k] = [...v.currentPack]
            })

            playersPack.round = (props.globalData.playersPack && props.globalData.playersPack.round) ? props.globalData.playersPack.round + 1 : 1
            let gData = { ...props.globalData }
            gData.playersPack = { ...playersPack }
            props.sendDataToOpponents({ global: gData })

            setTimeout(() => {
                setIsAllReady(false)
            }, 1000)
        }
    }, [isAllReady])

    const [round, setRound] = useState(0)

    useEffect(() => {
        if (!props.globalData.playersPack) { return }
        // seulment si nouveau round
        if (round >= props.globalData.playersPack.round) { return }
        setRound(props.globalData.playersPack.round)
        const newPack = props.globalData.playersPack[previousPlayerId]
        if (newPack.length <= 0) {
            if (remainingBoosters == 1) {
                // FINISH
                props.setIsDraftComplete(true)
                if (cardsPickedInOrder.length >= 48) {
                    let draftCardsData = []
                    cardsPickedInOrder.forEach((c) => {
                        draftCardsData.push(c.rarity[0] + " " + c.type + " " + c.faction + " " + c.manaCost[0] + "/" + c.manaCost[1] + " " + c.power[0] + "-" + c.power[1] + "-" + c.power[2] + " " + c.name + " " + c.id)
                    })
                    setDoc(doc(db, "draft-data", uuidv4()), {
                        date: new Date(),
                        booster1: draftCardsData.slice(0, 12),
                        booster2: draftCardsData.slice(12, 24),
                        booster3: draftCardsData.slice(24, 36),
                        booster4: draftCardsData.slice(36, 48)
                    });
                }
            } else {
                setRemainingBoosters(remainingBoosters - 1)
                // CHANGE ORDER
                let myIndex = props.turnOrder.findIndex((e) => e == props.playerId)
                if (remainingBoosters % 2 == 0) {
                    if (myIndex == props.turnOrder.length - 1) {
                        setPreviousPlayerId(props.turnOrder[0])
                    } else {
                        setPreviousPlayerId(props.turnOrder[myIndex + 1])
                    }
                } else {
                    if (myIndex == 0) {
                        setPreviousPlayerId(props.turnOrder[props.turnOrder.length - 1])
                    } else {
                        setPreviousPlayerId(props.turnOrder[myIndex - 1])
                    }
                }
            }
            setCurrentPack([])
            setIsOpening(false)
            setSwitchingAnimationProgress(false)
            console.log("NEW PACK")
        } else {
            setCurrentPack([...newPack])
            setTimeout(() => {
                setSwitchingAnimationProgress("entering")
            }, 200)
            setTimeout(() => {
                setSwitchingAnimationProgress("entered")
            }, 1300)
        }
        let myData = { ...props.playersData[props.playerId] }
        myData.isReady = false
        props.sendDataToOpponents({ player: myData })
        currentStatusRef.current = myData
    }, [props.globalData.playersPack])

    const openPack = () => {
        setIsOpening(true)
        let newPackTmp = []

        newPackTmp.push({ ...heroesRef.current[Math.floor(Math.random() * heroesRef.current.length)] })

        const randomPercentage = () => {
            return Math.round(Math.random() * 99) + 1
        }

        let commonsFactions = []

        // COMMON 0
        let percentage = randomPercentage()
        commonsFactions.push(percentage <= 50 ? "AXIOM" : "BRAVOS")

        // COMMON 1
        commonsFactions.push(commonsFactions[0] == "AXIOM" ? "BRAVOS" : "AXIOM")

        // COMMON 2
        percentage = randomPercentage()
        commonsFactions.push(percentage <= 50 ? (commonsFactions[1] == "AXIOM" ? "BRAVOS" : "AXIOM") : (percentage <= 75 ? "MUNA" : "LYRA"))

        // COMMON 3
        percentage = randomPercentage()
        commonsFactions.push(percentage <= 50 ? "MUNA" : "LYRA")

        // COMMON 4
        percentage = randomPercentage()
        commonsFactions.push(commonsFactions[3] == "MUNA" ? "LYRA" : "MUNA")

        // COMMON 5
        // SI DEJA MUNA EN 2, FORCEMENT OR-YZ
        percentage = randomPercentage()
        commonsFactions.push(commonsFactions[2] == "MUNA" || commonsFactions[2] == "LYRA" ? (percentage <= 50 ? "ORDIS" : "YZMIR") : (percentage <= 50 ? (commonsFactions[4] == "MUNA" ? "LYRA" : "MUNA") : (percentage <= 75 ? "ORDIS" : "YZMIR")))

        // COMMON 6
        percentage = randomPercentage()
        commonsFactions.push(percentage <= 50 ? "ORDIS" : "YZMIR")

        // COMMON 7
        percentage = randomPercentage()
        commonsFactions.push(commonsFactions[6] == "YZMIR" ? "ORDIS" : "YZMIR")

        // SWAP 3-4 AND 6-7 IF SAME FACTION IN A ROW
        function swapInArray(arr, i1, i2) {
            let t = arr[i1];
            arr[i1] = arr[i2];
            arr[i2] = t;
        }

        if (commonsFactions[2] == commonsFactions[3]) {
            swapInArray(commonsFactions, 3, 4)
        }
        if (commonsFactions[4] == commonsFactions[5]) {
            swapInArray(commonsFactions, 3, 4)
        }

        if (commonsFactions[5] == commonsFactions[6]) {
            swapInArray(commonsFactions, 6, 7)
        }

        for (let i = 0; i < 8; i++) {
            let c = { ...commonsRef.current[commonsFactions[i]][Math.floor(Math.random() * commonsRef.current[commonsFactions[i]].length)] }
            newPackTmp.push(c)
        }

        for (let i = 0; i < 3; i++) {
            let c = { ...raresRef.current[Math.floor(Math.random() * raresRef.current.length)] }
            newPackTmp.push(c)
        }

        for (let n = 0; n < newPackTmp.length; n++) {
            newPackTmp[n].cardId = uuidv4() + "n"
        }
        setCurrentPack(newPackTmp)
    }

    const cardPicked = (card, i) => {
        if (!(switchingAnimationProgress == "entered" || switchingAnimationProgress === false)) { return }
        let tmp = [...currentPack]
        tmp[i].picked = true
        setCurrentPack(tmp)
        setSwitchingAnimationProgress("leaving")

        setTimeout(() => {
            let tmpToSend = []
            for (let i = 0; i < tmp.length; i++) {
                if (!tmp[i].picked) {
                    tmpToSend.push({ ...tmp[i] })
                } else {
                    let ord = [...cardsPickedInOrder]
                    ord.push({ ...tmp[i] })
                    setCardsPickedInOrder(ord)
                    addToCardsPicked({ ...tmp[i] })
                }
            }
            setSwitchingAnimationProgress("switching")
            let myData = { ...props.playersData[props.playerId] }
            myData.currentPack = [...tmpToSend]
            myData.isReady = true
            props.sendDataToOpponents({ player: myData })
            currentStatusRef.current = myData
        }, 1100)
    }

    const addToCardsPicked = (card) => {
        delete card.picked
        let d = [...props.cardsPicked]
        d.push(card)
        d.sort((a, b) => a.faction.localeCompare(b.faction));
        props.setCardsPicked(d)
    }

    return (<div class={"draft-panel drafting" + (isOpening ? " pack-opening" : "") + (switchingAnimationProgress ? " " + switchingAnimationProgress : "")} >
        <div class="background-wrapper">
            <div class="blurred-background"></div>
        </div>
        <Navigation />
        <div class="participants d-flex flex-row">
            {props.turnOrder.map((id) => {
                let data = props.playersData[id]
                return (<UserName className={data.isReady ? "ready" : ""} name={data.userInfo.name} />)
            })}
            <div class="nav-items d-flex flex-row">
                <LanguageSelect />
                <DarkMode />
            </div>
        </div>
        <div class="draft-section">
            <div class="cards">
                {currentPack.map((c, i) => {
                    return (
                        <div key={c.cardId + i} class={"card-wrapper" + (c.rarity == "RARE" ? " rare" : "") + (c.picked ? " picked" : "")} onClick={() => cardPicked(c, i)}  >
                            <ImageMemo src={c.images ? c.images[i18n.language] : c.image} className="card" />
                            <img class="card-back" src={require('../VirtualDeck/Assets/card-back.png')} />
                            <div class="zoom-hover"></div>
                        </div>
                    )
                })}
            </div>

            <PackWrapper openPack={openPack} isOpening={isOpening} remainingBoosters={remainingBoosters} />

            {props.cardsPicked && (<Button class={"cards-picked-show" + (showCardsPickedPanel ? " top" : "")} onClick={() => setShowCardsPickedPanel(!showCardsPickedPanel)}>{showCardsPickedPanel ? t("draft.hide_pick") : t("draft.see_pick")} ({props.cardsPicked.length})</Button>)}

            {!isAllReady && isOpening && props.playersData[props.playerId].isReady && (<h2 class="waiting-messgage">{t("draft.waiting_pick")}</h2>)}
            <CardsPicked showPanel={showCardsPickedPanel} cardsPicked={props.cardsPicked} />
        </div>


    </div>)
}

function PackWrapper(props) {
    const [cardTransform, setCardTransform] = useState({
        rotationY: 0,
        rotationX: 0,
        imgOffsetX: 0
    }

    )
    function rotateToMouse(e) {
        const card = e.currentTarget;
        const x = e.pageX - card.offsetLeft;
        const y = e.pageY - card.offsetTop;
        const px = x / card.offsetWidth;
        const py = y / card.offsetHeight;
        const xx = -15 + 30 * px;
        const yy = 15 - 30 * py;

        setCardTransform({
            rotationY: xx,
            rotationX: yy / 2,
            scale: 1.05
        });
    }

    return (
        <div class="pack-wrapper"
            onClick={() => props.openPack()}
            onMouseMove={(e) => {
                rotateToMouse(e)
            }}
            onMouseLeave={() => {
                setCardTransform({
                    rotationY: 0,
                    rotationX: 0,
                    scale: 1
                })
            }}>
            <div class="h-100 w-100 position-absolute top-0" style={{
                transform: `perspective(1000px) rotateY(${cardTransform.rotationY}deg) rotateX(${cardTransform.rotationX}deg) scale(${cardTransform.scale})`
            }} >
                <div class="pack-left pack-part">
                    <img class="booster-pack" src={require('./Assets/booster.png')} />
                </div>
                <div class="pack-right pack-part">
                    <img class="booster-pack" src={require('./Assets/booster.png')} />
                </div>
                <div class="position-absolute top-0 w-100 h-100 pe-none overflow-hidden">
                    <div class="pack-cut"></div>
                </div>
            </div>
            {!props.isOpening && (<h2>x{props.remainingBoosters}</h2>)}
        </div>
    )
}

function CardsPicked(props) {
    const { i18n } = useLanguageContext()

    return (<div class={"cards-picked-panel w-100 h-100" + (props.showPanel ? "" : " hidden")}>
        <div class="blurred-background"></div>
        <div class="cards-picked-wrapper hide-scrollbar">
            <div class="cards-picked row row-cols-6">
                {props.cardsPicked.map((c) => {
                    return (<div class="card-picked col"><ImageMemo src={c.images ? c.images[i18n.language] : c.image} /><div class="zoom-hover"></div></div>)
                })}
            </div>
        </div>
    </div>)
}