import './Footer.css'

export default function Footer() {
    return(
        <div class="footer w-100 p-5 light-shadowed">
            <p>Equinox, Altered, and their logos are trademarks of Equinox. © 2023-2024 Equinox. All Rights Reserved.
            This project is fanwork. Exalts Table is not affiliated with, endorsed, sponsored, or specifically approved by Equinox.</p>
            <p class="m-0">Current version 1.20</p>
        </div>
    )
}