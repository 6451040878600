import { useEffect, useRef, useState } from "react"
import PageWithNavigation from "../../Commons/PageWithNavigation"
import "./WaitingRoom.css"
import "../../Game/Game.css"
import Button, { Switch } from "../../Commons/Buttons"
import { t } from "i18next"
import UserName from "../../Commons/UserName"
import { deleteDoc, doc, setDoc } from "firebase/firestore"
import { db } from "../../Commons/Firebase"

export default function WaitingRoom(props) {
    const [tokenColor, setTokenColor] = useState(1)
    const [isCopied, setIsCopied] = useState(false)
    //const roomUrl = "localhost:3000/lobby/" + props.roomId
    const roomUrl = "https://exalts-table.com/lobby/" + props.roomId

    const setFaction = (faction) => {
        setTokenColor(faction)
        props.setPlayerFaction(faction)
    }

    const [isLobbyPublic, setIsLobbyPublic] = useState(false)
    const isLobbyPublicRef = useRef(false)
    const publicLobbyIdRef = useRef("O")

    const switchRoomVisibility = async (isPublic) => {
        setIsLobbyPublic(isPublic)
        isLobbyPublicRef.current = isPublic
        if (isPublic) {
            publicLobbyIdRef.current = props.roomId
            await setDoc(doc(db, "public-lobbies", props.roomId), {
                creationTime: Math.floor(Date.now() / 1000),
                gameMode: props.gameModeInfo.modeId,
                hostName: props.userInfo.name,
                roomId: props.roomId
            });
        } else {
            await deleteDoc(doc(db, "public-lobbies", props.roomId));
        }
    }

    useEffect(() => {
        const unload = async (e) => {
            //e.preventDefault()
            if (isLobbyPublicRef.current) {
                await deleteDoc(doc(db, "public-lobbies", publicLobbyIdRef.current));
            }
            //return (e.returnValue = 'Are you sure you want to close?')
        }
        window.addEventListener('beforeunload', unload)
        return () => {
            window.removeEventListener(
                'beforeunload',
                unload,
            );
        };
    }, [])

    const teamACount = props.usersInfo ? props.usersInfo.filter((u) => u.team == 1).length : 0

    return (
        <PageWithNavigation selected="play" description={(props.isHost ? t("play.lobby.description_host") : t("play.lobby.description"))} component={
            <div>
                <div class="w-100 d-flex flex-row portrait-only justify-content-center">
                    <h3>This app is not made to be played on a phone</h3>
                </div>
                <div class="d-flex flex-column justify-content-around landscape-only">
                    {props.gameModeInfo && (<div class="d-flex flex-row row justify-content-around">
                        <div class="d-flex flex-column col-md-6 box-container">
                            <h2>{props.gameModeInfo.title}</h2>
                            <p class="text-start my-4">{props.gameModeInfo.description}</p>
                            {props.roomId && (
                                <div class="d-flex flex-column mt-4">
                                    <div class="d-flex flex-row justify-content-between"><p>{t("play.lobby.share_room")}</p><h3>{t("play.players")} {props.currentPlayers}/{props.gameModeInfo.players}</h3></div>
                                    <div class="d-flex flex-row input-with-button">
                                        <p class="w-100 d-flex align-items-center ps-2">{roomUrl}</p>
                                        <button class="wide" onClick={() => {
                                            navigator.clipboard.writeText(roomUrl)
                                            setIsCopied(true)
                                            setTimeout(() => { setIsCopied(false) }, 2000)
                                        }
                                        }>{isCopied ? t("play.lobby.copied") : t("play.lobby.copy")}</button>
                                    </div>
                                </div>
                            )}
                            {props.usersInfo && (<div class="mb-4">
                                {props.usersInfo.map((u, i) => {
                                    return (<div key={u.id} class={"d-flex flex-row justify-content-between align-items-center user-info position-relative" + (props.playerId == u.id ? " you" : "") + (props.gameModeInfo.modeId == "_2v2_standard" ? (u.team == 1 ? " team-B-background" : " team-A-background") : "")}>
                                        <p class="user-turn">{i + 1}</p>
                                        {props.gameModeInfo.modeId == "_2v2_standard" && (<div class="d-flex flex-row align-items-start h-100 position-absolute start-50 translate-middle-x top-0">
                                            {props.isHost && i > 0 && (<Button class="ms-2" onClick={() => props.switchTeamForUser(i)}>Switch team</Button>)}
                                        </div>)}
                                        <UserName name={u.data.name} />
                                    </div>)
                                })}
                            </div>)}
                            {props.gameModeInfo.modeId == "_2v2_standard" && props.usersInfo.length == 4 && teamACount != 2 && (<p>{teamACount == 4 || teamACount == 0 ? "Congrats you all won !" : "You need even teams."}</p>)}
                            {!props.isHost && (props.currentPlayers == props.gameModeInfo.players || props.gameModeInfo.players == "8") && (<p>{t("play.lobby.waiting_for_host")}</p>)}
                            {props.isHost && (props.currentPlayers == props.gameModeInfo.players || props.gameModeInfo.players == "8") && (<button onClick={async () => {
                                props.startGame()
                                if (isLobbyPublic) {
                                    await deleteDoc(doc(db, "public-lobbies", props.roomId));
                                }
                            }}>{t("commons.start")}</button>)}
                        </div>
                        <div class="vertical-divider"></div>
                        {!(props.isHost && props.gameModeInfo.modeId == "_1v1_standard_observer") && (
                            <div class="d-flex flex-column col-md-6 box-container">
                                <h2 class="mb-4">{t("game.menu.button_options.pannel_title")}</h2>
                                {props.isHost && (<div class="d-flex flex-row justify-content between align-items-center mb-4"><p class="m-0 w-50">Lobby visibility</p><Switch isEnabled={isLobbyPublic} enabledMessage={"Public"} disabledMessage={"Private"} onClick={() => switchRoomVisibility(!isLobbyPublic)} /></div>)}
                                <div class="d-flex flex-row input-with-button">
                                    <input type="text" id="UserPseudo" placeholder="Your name" defaultValue={props.userInfo.name} maxlength="14" class="form-control" aria-describedby="basic-addon3"></input>
                                    <button class="wide" onClick={() => {
                                        const newName = document.getElementById('UserPseudo').value
                                        localStorage.setItem('userName', newName);
                                        props.updateUserName(newName)
                                    }}>Change name</button>
                                </div>
                                {props.gameModeInfo.modeId != "_draft" && (
                                    <div class="d-flex flex-column">
                                        <p>{t("game.menu.button_options.pannel_content_faction_title")}</p>
                                        <div class="token-selection d-flex flex-row justify-content-center">
                                            <TokenSelectionButton value={1} tokenColor={tokenColor} setTokenColor={setFaction} />
                                            <TokenSelectionButton value={2} tokenColor={tokenColor} setTokenColor={setFaction} />
                                            <TokenSelectionButton value={3} tokenColor={tokenColor} setTokenColor={setFaction} />
                                            <TokenSelectionButton value={4} tokenColor={tokenColor} setTokenColor={setFaction} />
                                            <TokenSelectionButton value={5} tokenColor={tokenColor} setTokenColor={setFaction} />
                                            <TokenSelectionButton value={6} tokenColor={tokenColor} setTokenColor={setFaction} />
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>)}
                </div>
            </div>
        } />
    )

    function TokenSelectionButton(props) {
        const value = props.value
        return (
            <Button class={props.tokenColor == value ? "selected" : ""} onClick={
                () => {
                    props.setTokenColor(value)
                }}>
                <img src={require('../../Game/Tumulte/PlayerIcons/player-' + value + '-1.png')} />
            </Button>
        )
    }
}