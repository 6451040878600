import { useState, useEffect, useLayoutEffect, useRef } from 'react'
import './Tumulte2v2.css'
import React from 'react'
import Button from '../../Commons/Buttons'

export default function TumulteSection2v2(props) {
    const setPlayerPosition = (type, value) => {
        props.setPlayerPosition(type, value)
    }
    
    const myId = props.playersRef[0].id
    const myTurnIndex = useRef(props.globalData.turnOrder.findIndex(x => x == myId) + 1)
    const isFFA = useRef(props.globalData.gameModeInfo.modeId == "_FFA_4_players")

    if (!props.playerRefIndexes.tumulteSection || props.globalData.tumulteCards.length < 5) {
        return (<div></div>)
    }

    const player1 = props.playersData[props.globalData.turnOrder[0]]
    const player2 = props.playersData[props.globalData.turnOrder[1]]
    const player3 = props.playersData[props.globalData.turnOrder[2]]
    const player4 = props.playersData[props.globalData.turnOrder[3]]



    return (
        <div class={"tumulte-section"}>
            <div class="west">
                <div class="section d-flex flex-row justify-content-center">
                    <TumulteStart value={0} />
                    <TumulteCard tumulteData={props.globalData.tumulteCards[4]} />
                </div>
            </div>
            <div class="east">
                <div class="section d-flex flex-row justify-content-center">
                    <TumulteStart value={0} />
                    <TumulteCard tumulteData={props.globalData.tumulteCards[2]} />
                </div>
            </div>
            <div class="center">
                <TumulteCenter tumulteData={props.globalData.tumulteCards[0]} />
            </div>
            <div class="north">
                <div class="section d-flex flex-row justify-content-center">
                    <TumulteCard tumulteData={props.globalData.tumulteCards[1]} />
                    <TumulteStart value={7} />
                </div>
            </div>
            <div class="south">
                <div class="section d-flex flex-row justify-content-center">
                    <TumulteCard tumulteData={props.globalData.tumulteCards[3]} />
                    <TumulteStart value={7} />
                </div>
            </div>

            <div class="player-tokens-area">
                <HeroToken yourPlayerNumber={myTurnIndex.current} playerNumber={1} isFFA={isFFA.current} tokenColor={player1.faction} value={player1.tumultePositions[0]} setPlayerPosition={setPlayerPosition} />
                <CompanionToken yourPlayerNumber={myTurnIndex.current} playerNumber={1} isFFA={isFFA.current} tokenColor={player1.faction} value={player1.tumultePositions[1]} setPlayerPosition={setPlayerPosition} />

                <HeroToken yourPlayerNumber={myTurnIndex.current} playerNumber={2} isFFA={isFFA.current} tokenColor={player2.faction} value={player2.tumultePositions[0]} setPlayerPosition={setPlayerPosition} />
                <CompanionToken yourPlayerNumber={myTurnIndex.current} playerNumber={2} isFFA={isFFA.current} tokenColor={player2.faction} value={player2.tumultePositions[1]} setPlayerPosition={setPlayerPosition} />

                <HeroToken yourPlayerNumber={myTurnIndex.current} playerNumber={3} isFFA={isFFA.current} tokenColor={player3.faction} value={player3.tumultePositions[0]} setPlayerPosition={setPlayerPosition} />
                <CompanionToken yourPlayerNumber={myTurnIndex.current} playerNumber={3} isFFA={isFFA.current} tokenColor={player3.faction} value={player3.tumultePositions[1]} setPlayerPosition={setPlayerPosition} />

                <HeroToken yourPlayerNumber={myTurnIndex.current} playerNumber={4} isFFA={isFFA.current} tokenColor={player4.faction} value={player4.tumultePositions[0]} setPlayerPosition={setPlayerPosition} />
                <CompanionToken yourPlayerNumber={myTurnIndex.current} playerNumber={4} isFFA={isFFA.current} tokenColor={player4.faction} value={player4.tumultePositions[1]} setPlayerPosition={setPlayerPosition} />
            </div>
        </div>
    )

    function TumulteStart(props) {
        const value = props.value
        const Image = React.memo(function Image({ src, className }) {
            return <img src={src} className={className} />;
        });
        return (
            <div class="tumulte-start">
                {<Image className={(value == 0 ? "" : "") + (" tumulte-card-image shadowed")} src={require(value == 0 ? './TumulteCards/hero-start.png' : './TumulteCards/companion-start.png')} />}
            </div>
        )
    }
}

function TumulteCard(props) {
    const tumulteData = props.tumulteData
    const Image = React.memo(function Image({ src, className }) {
        return <img src={src} className={className} />;
    });
    return (
        <div class="tumulte-card">
            <Image className={(tumulteData.hasBeenRevealed ? "hide " : "") + "tumulte-card-image card-back shadowed"} src={require('./TumulteCards/tumulte-back.png')} />
            <Image className={(tumulteData.hasBeenRevealed ? "" : "hide ") + ("tumulte-card-image ")} src={require('./TumulteCards/' + tumulteData.data.image)} />
        </div>
    )
}

function TumulteCenter(props) {
    const tumulteData = props.tumulteData
    const Image = React.memo(function Image({ src, className }) {
        return <img src={src} className={className} />;
    });
    /*
    return (
        <div class="tumulte-card center">
            <Image className={(tumulteData.hasBeenRevealed ? "hide " : "") + "tumulte-card-image card-back shadowed"} src={require('./TumulteCards/tumulte-back.png')} />
            <Image className={(tumulteData.hasBeenRevealed ? "" : "hide ") + ("tumulte-card-image ")} src={require('./TumulteCards/' + tumulteData.image)} />
        </div>
    )*/
    return (
        <div class="tumulte-card center">
            <Image className={(tumulteData.hasBeenRevealed ? "hide " : "") + "tumulte-card-image card-back shadowed"} src={require('./TumulteCards/2v2/2v2-tumulte-back.jpg')} />
            <Image className={(tumulteData.hasBeenRevealed ? "" : "hide ") + ("tumulte-card-image ")} src={require('./TumulteCards/2v2/' + tumulteData.data.image)} />
        </div>
    )
}

function HeroToken(props) {
    return (
        <PlayerToken yourPlayerNumber={props.yourPlayerNumber} type="hero" playerNumber={props.playerNumber} isFFA={props.isFFA} color={props.tokenColor} value={props.value} setPlayerPosition={props.setPlayerPosition} />
    )
}

function CompanionToken(props) {
    return (
        <PlayerToken yourPlayerNumber={props.yourPlayerNumber} type="companion" playerNumber={props.playerNumber} isFFA={props.isFFA} color={props.tokenColor} value={props.value} setPlayerPosition={props.setPlayerPosition} />
    )
}


function PlayerToken(props) {
    const Image = React.memo(function Image({ src, className }) {
        return <img src={src} className={className} />;
    });
    var art = ""
    const isHero = props.type == "hero"
    if (isHero) {
        art = "PlayerIcons/player-" + props.color + "-1.png"
    } else {
        art = "PlayerIcons/player-" + props.color + "-2.png"
    }

    return (
        <div class={(props.isFFA ? (props.playerNumber == props.yourPlayerNumber ? "player " : "opponent ") : (props.playerNumber % 2 == 0 ? "player " : "opponent ")) + (props.playerNumber == props.yourPlayerNumber ? " yours ": "not-yours ") + ("player-" + props.playerNumber + " ") + (isHero ? "hero " : "companion ") + "player-token" + (" position-" + props.value)}>
            <div class={"player-token-offset "} >
                {props.playerNumber && (
                    <div class="position-relative token-move-buttons">
                        <div class="d-flex flex-row">
                            <Button onClick={() => props.setPlayerPosition(props.type, props.value - 1)}><img src={require("../Icons/left.png")} /></Button>
                            <Button onClick={() => props.setPlayerPosition(props.type, props.value + 1)}><img src={require("../Icons/right.png")} /></Button>
                        </div>
                    </div>
                )}
                {<Image className="tumulte-position-token glow" src={require('./' + art)} />}
            </div>
        </div>
    )
}
/*
export const TumulteMemo = React.memo(({ globalData, playersData, setPlayerPosition, playersRef, isSpectator, playerRefIndexes }) => {
    console.log("Tumulte rerendered")
    return (<TumulteSection globalData={globalData} playersData={playersData} playersRef={playersRef} setPlayerPosition={setPlayerPosition} isSpectator={isSpectator} playerRefIndexes={playerRefIndexes} />)
})
*/
