import { useState, useEffect, useRef } from 'react';
import GameReborn from './GameReborn';
import { chatDefault, globalDefault, opponentDefault, tumulte2playersDefault } from '../Home/Lobby/ConnectionHandlerHost';
import { gameModesInfo } from '../Commons/GameModes';


export default function TestGameLobby() {

    const [gameData, setGameData] = useState({
        global: {
            gameCount: 0,
            tumulteCards: [{
                image: "tumulte-1-1.png",
                hasBeenRevealed: false
            }, {
                image: "tumulte-1-1.png",
                hasBeenRevealed: false
            }, {
                image: "tumulte-1-1.png",
                hasBeenRevealed: false
            }],
            turnCounter: 1,
            firstPlayerIndex: 0,
            activePlayerIndex: 0,
            turnOrder: ["1", "2", "3", "4"],
            gameModeInfo: gameModesInfo["_FFA_4_players"],
            lastPlayedCard: false
        },
        players: {
            "1": JSON.parse(JSON.stringify(opponentDefault)),
            "2": JSON.parse(JSON.stringify(opponentDefault)),
            "3": JSON.parse(JSON.stringify(opponentDefault)),
            "4": JSON.parse(JSON.stringify(opponentDefault))
        },
        chat: { ...chatDefault }
    })
    const playersRef = useRef([
        { connection: null, id: "1", calls: [], stream: false },
        { connection: null, id: "2" },
        { connection: null, id: "3" },
        { connection: null, id: "4" }])

    
   /*
     const [gameData, setGameData] = useState({
         global: {
             gameCount: 0,
             tumulteCards: JSON.parse(JSON.stringify(tumulte2playersDefault)),
             turnCounter: 1,
             gameModeInfo: {...gameModesInfo["_1v1_standard"]},
             firstPlayerIndex: 0,
             activePlayerIndex: 0,
             turnOrder: ["2", "1"],
             lastPlayedCard: false
         },
         players: {
             "1": JSON.parse(JSON.stringify(opponentDefault)),
             "2": JSON.parse(JSON.stringify(opponentDefault))
         },
         chat: {...chatDefault}
     })
     console.log(gameModesInfo["_1v1_standard"])
     
     const playersRef = useRef([
         {connection: null, id: "1", calls: [], stream: false}, 
         {connection: null, id: "2"}])
 */
    const gameDataRef = useRef(gameData)
    useEffect(() => {
        setTimeout(() => {
            //updateGameData({...gameDataRef.current})
        }, 1000)
        let tmp = { ...gameDataRef.current }
        if (tmp.global.gameModeInfo.modeId == "_1v1_standard") {
            tmp.players["1"].powerCountersOpponentsId.hero = "2"
            tmp.players["1"].powerCountersOpponentsId.companion = "2"
            tmp.players["2"].powerCountersOpponentsId.hero = "1"
            tmp.players["2"].powerCountersOpponentsId.companion = "1"
        } else {
            tmp.players["1"].powerCountersOpponentsId.hero = "4"
            tmp.players["1"].powerCountersOpponentsId.companion = "2"
            tmp.players["2"].powerCountersOpponentsId.hero = "3"
            tmp.players["2"].powerCountersOpponentsId.companion = "1"
            tmp.players["3"].powerCountersOpponentsId.hero = "4"
            tmp.players["3"].powerCountersOpponentsId.companion = "2"
            tmp.players["4"].powerCountersOpponentsId.hero = "1"
            tmp.players["4"].powerCountersOpponentsId.companion = "3"
        }
        updateGameData(tmp)
    }, [])


    const sendGameData = (data) => {
        if (data.player) {
            let tmp = { ...gameDataRef.current }
            tmp.players["1"] = data.player
            let s = tmp.players["2"].powerCountersOpponentsId
            tmp.players["2"] = {...data.player}
            tmp.players["2"].powerCountersOpponentsId = s
            if (gameData.global.gameModeInfo && gameData.global.gameModeInfo.players == 4) {
                tmp.players["3"] = {...data.player}
                s = tmp.players["4"].powerCountersOpponentsId
                tmp.players["4"] = {...data.player}
                tmp.players["4"].powerCountersOpponentsId = s
            }
            updateGameData(tmp)
        }
        if (data.global) {
            let tmp = { ...gameDataRef.current }
            tmp.global = data.global
            updateGameData(tmp)
        }
        if (data.newMessage) {
            let tmp = { ...gameDataRef.current }
            tmp.chat.messages.push(data.newMessage)
            updateGameData(tmp)
        }
    }

    const updateGameData = (data) => {
        setGameData({ ...data })
        gameDataRef.current = { ...data }
        // mettre le ref dans setgamedata
    }

    return (<GameReborn isHost={true} playersRef={playersRef.current} playerId={"1"} gameDataGlobal={gameData.global} gameDataPlayers={gameData.players} chat={gameData.chat} sendDataToOpponents={(data) => { sendGameData(data) }} endCall={() => { }} />);
}