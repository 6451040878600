import logo from './logo.svg';
import './App.css';
import TextRecognizer from './TextRecognition/TextRecognizer';
import ImageCropper from './ImageCropping/ImageCropper.jsx';
import TestGame from './Game/TestGame.jsx';
import PhoneSetup from './CameraSetup/PhoneSetup.jsx';
import DesktopSetup from './CameraSetup/DesktopSetup.jsx';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './Home/Home.jsx';
import OpponentConnection from './OppoenentConnection/OpponentConnection.jsx';
import About from './Home/About.jsx';
import DeckSetup from './VirtualDeck/DeckSetup/DeckSetup.jsx';
import VirtualBoard from './VirtualDeck/VirtualBoard.jsx';
import VirtualOpponentConnection from './VirtualDeck/VirtualOpponentConnection.jsx';
import DeckSetupLink, { DeckSetupSecret } from './VirtualDeck/DeckSetup/DeckSetupLink.jsx';
import RelayConnection from './OppoenentConnection/RelayConnection.jsx';
import Play from './Home/Play/Play.jsx';
import Help from './Home/Help/Help.jsx';
import WaitingRoom from './Home/Lobby/WaitingRoom.jsx';
import Lobby from './Home/Lobby/Lobby.jsx';
import ConnectionHandler from './Home/Lobby/ConnectionHandler.jsx';
import TestGameLobby from './Game/TestGameLobby.jsx';
import Draft from './Draft/Draft.jsx';
import SingleplayerGame from './Game/Singleplayer/SingeplayerGame.jsx';
//local-ssl-proxy --source 3001 --target 3000


function App() {
  return (
    <div className="App">
      <header className="App-header">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/draft" element={<Draft />} />
            {/*<Route path="/play" element={<Play />} />*/}
            <Route path="/help" element={<Help />} />
            {/*<Route path="/webcam-play" element={<DesktopSetup />} />*/}
            {/*<Route path="/play-test" element={<TestGame />} />*/}
            <Route path="/about" element={<About />} />
            <Route path="/deck-test" element={<DeckSetup />} />
            <Route path="/deck-test/secret" element={<DeckSetupSecret />} />
            <Route path="/deck-test/link" element={<DeckSetupLink />} />
            <Route path="/virtual-board" element={<VirtualBoard />} />
            {/*<Route path="/virtual-play" element={<VirtualOpponentConnection />} />*/}
            {/*<Route path="/relay" element={<RelayConnection />} />*/}
            <Route path="/mobile/:remotePeerIdValue" element={<PhoneSetup />} />

            <Route path="/lobby/:gameId" element={<ConnectionHandler />} />
            <Route path="/play" element={<Lobby />} />

            <Route path="/lobby-test" element={<TestGameLobby />} />

            <Route path="/single-player" element={<SingleplayerGame />} />
          </Routes>
        </BrowserRouter>
        {/*<Game />*/}

      </header>

    </div >
  );
}

export default App;
