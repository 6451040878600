import React, { useEffect, useRef, useState } from "react"
import "./PlayerGameSection.css"
import { VirtualBoardGameViewMemo } from "../Memos"
import { TransitionGroup } from "react-transition-group"
import Button from "../../../Commons/Buttons"
import ReactPlayer from "react-player"
import { createEmptyMediaStream } from "../../../QRCodeRecognition/Functions"
import Peer from "peerjs"
import QRCode from "react-qr-code"
import { useLanguageContext } from "../../../Commons/Translations/languageContext"
import { Counters } from "../PowerCounter"
import UserName from "../../../Commons/UserName"
import { Locked } from "../../../VirtualDeck/DeckSetup/DeckSetup"

export default function PlayerGameSection(props) {
    const { t } = useLanguageContext()
    const gameMode = props.playerData.gameMode
    const isReady = gameMode != "undefined"
    const playerIndex = props.playerIndex
    const isYourSection = props.isYourSection
    const isFirstPlayer = playerIndex == props.firstPlayerIndex
    const isActivePlayer = props.globalData.activePlayerIndex == playerIndex
    const isFocused = playerIndex === props.focusedPlayerGameSection


    const [flipHorizontal, setFlipHorizontal] = useState(false)
    const [flipVertical, setFlipVertical] = useState(false)

    const is4Players = props.globalData.gameModeInfo.players == 4

    const flipH = () => {
        let newVal = !flipHorizontal
        setFlipHorizontal(newVal)
        props.playerRef.flipH = newVal
    }

    const flipV = () => {
        let newVal = !flipVertical
        setFlipVertical(newVal)
        props.playerRef.flipV = newVal
    }

    const replaceStream = (newStream) => {
        for (let i = 0; i < props.playerRef.calls.length; i++) {
            props.playerRef.calls[i].peerConnection.getSenders()[0].replaceTrack(newStream.getTracks()[0])
        }
        props.playerRef.stream = newStream
    }

    // QR CODE MOBILE

    useEffect(() => {
        if (isYourSection && !props.isMini && !props.playerRef.mobilePeer) {
            iniPeer()
        }
    })

    const iniPeer = () => {
        delete props.playerRef.mobilePeer
        delete props.playerRef.mobilePeerId
        delete props.playerRef.mobileCall
        let peer = new Peer()

        peer.on('open', (id) => {
            props.playerRef.mobilePeerId = id
        });

        peer.on('call', (call) => {
            try {
                call.answer()
                call.on('stream', function (remoteStream) {
                    replaceStream(remoteStream)
                    setGameMode("webcam")
                    props.playerRef.mobileCall = call;
                });
            } catch (error) {
                console.error(error);
            }
        })

        props.playerRef.mobilePeer = peer;
    }


    const setGameMode = (mode) => {
        if (!isYourSection) { return }
        if (mode == "undefined") {
            if (props.playerRef.stream) {
                props.playerRef.stream.getTracks().forEach(function (track) {
                    track.stop();
                })
            }
            replaceStream(createEmptyMediaStream())
            iniPeer()
        } else if (mode == "virtual") {
            props.triggerVirtualChangeBackground(0)
        }
        let tmp = { ...props.playerData }
        tmp.gameMode = mode
        props.setPlayerGameData(tmp)
    }

    const [isVideoZoomed, setIsVideoZoomed] = useState(-1)

    return (
        <div class={"player-game-section undraggable" + (isFocused ? " focused" : " not-focused") + (isYourSection ? " your-section" : "") + (isFirstPlayer ? " is-first-player" : "") + (isActivePlayer ? " is-active-player" : "") + " player-" + playerIndex} >
            <div class="h-100 w-100" onClick={() => {
                if (!isFocused && props.isMini) {
                    props.setFocusedPlayerGameSection(playerIndex)
                }
            }}>
                {!isReady ? (
                    <div class="mode-selection">
                        <div class="selection-panel">
                            {isYourSection ? (
                                <GameModeSetup setGameMode={setGameMode} playerRef={props.playerRef} replaceStream={replaceStream} />
                            ) : (
                                <div class="d-flex flex-column align-items-center justify-content-center h-100">
                                    {<div class="blurred-background"></div>}
                                    <p>{t("game.game_section.setup.waiting_for_opponent")}</p>
                                </div>
                            )}
                        </div>
                    </div>
                ) : (
                    <div class="h-100">
                        <div class="game-section h-100" >
                            {gameMode == "virtual" && isYourSection && !props.isMini && (<VirtualBoardGameViewMemo zoomVirtualCard={props.zoomVirtualCard} isYourSection={true} isOpponent={false} isSpectator={false} useManualMode={props.useManualMode} updateBoardData={props.updateBoardData} resetVirtualDeck={props.resetVirtualDeck} newTurnVirtualDeck={props.newTurnVirtualDeck} changeBackgroundVirtualDeck={props.changeBackgroundVirtualDeck} useMiniCards={is4Players && !isFocused} />)}
                            {gameMode == "virtual" && isYourSection && props.isMini && (<VirtualBoardGameViewMemo isYourSection={true} isOpponent={false} isSpectator={false} boardData={props.playerData.boardData} />)}
                            {gameMode == "virtual" && !isYourSection && (<VirtualBoardGameViewMemo zoomVirtualCard={props.zoomVirtualCard} isYourSection={false} isOpponent={true} isSpectator={props.isSpectator} boardData={props.playerData.boardData} useMiniCards={is4Players && !props.isMini && !isFocused} />)}
                            {gameMode == "webcam" && props.playerRef.stream && !props.isMini && (
                                <div class={"video zoomable" + (props.playerRef.flipH ? " flip-horizontal" : "") + (props.playerRef.flipV ? " flip-vertical" : "")} onMouseDown={() => {
                                    setIsVideoZoomed(1)
                                }}
                                    onMouseUp={() => {
                                        setIsVideoZoomed(0)
                                    }}
                                    onMouseLeave={() => {
                                        setIsVideoZoomed(-1)
                                    }}
                                    onMouseEnter={() => {
                                        setIsVideoZoomed(0)
                                    }}
                                    onMouseMove={() => {
                                        if (isVideoZoomed == -1) {
                                            setIsVideoZoomed(0)
                                        }
                                    }}
                                >
                                    {/*<ReactPlayer volume={0} playing={true} muted={true} url={props.playerRef.stream} width="100%" height="100%" />*/}
                                    <ZoomableVideo isVideoZoomed={isVideoZoomed} stream={props.playerRef.stream} />
                                </div>)}
                            {gameMode == "webcam" && props.playerRef.stream && props.isMini && (
                                <div class={"video" + (props.playerRef.flipH ? " flip-horizontal" : "") + (props.playerRef.flipV ? " flip-vertical" : "")} >
                                    <ReactPlayer volume={0} playing={true} muted={true} url={props.playerRef.stream} width="100%" height="100%" />
                                </div>)}
                            {/*<div class="QRCode-found-feedback"></div>*/}
                        </div>
                        <div class={"d-flex flex-row top-bar align-items-center" + (props.globalData.gameModeInfo.modeId == "_2v2_standard" ? (playerIndex % 2 == 1 ? " team-A-background" : " team-B-background"): "")}>
                            <UserName name={props.playerData.userInfo ? props.playerData.userInfo.name : ("Player " + (playerIndex + 1))} />
                            {/*props.globalData.gameModeInfo.modeId == "_2v2_standard" && (<img class="h-100 keep-visible" src={require("../Assets/" + (playerIndex % 2 == 1 ? "Blue-low.png" : "Red-low.png"))}/>)*/}
                            <Button class="secondary" onClick={() => props.setFocusedPlayerGameSection(props.focusedPlayerGameSection === false ? playerIndex : false)}><img src={require(('../../Icons/') + (props.focusedPlayerGameSection !== false ? "minimize.png" : "expand.png"))} /></Button>
                            {isYourSection && (<ButtonWithConfirmation class="secondary" onClick={() => setGameMode("undefined")}>{t("game.game_section.menu.change_setup")}</ButtonWithConfirmation>)}
                            {gameMode == "virtual" && (
                                <div class="d-flex flex-row">
                                    {isYourSection && (
                                        <div class="d-flex flex-row">
                                            <ButtonWithConfirmation class="secondary" onClick={() => {
                                                props.triggerVirtualDeckReset()
                                            }}>{t("game.game_section.menu.reset_deck")}</ButtonWithConfirmation>
                                            <Button class="secondary" onClick={() => {
                                                props.triggerVirtualChangeBackground()
                                            }}><img src={require('../../Icons/image.png')} /></Button>
                                        </div>)}
                                </div>)}
                            {gameMode == "webcam" && (
                                <div class="d-flex flex-row">
                                    <Button class="secondary" onClick={() => flipH()} selected={flipHorizontal} ><img src={require('../../Icons/flip-horizontal.png')} /></Button>
                                    <Button class="secondary" onClick={() => flipV()} selected={flipVertical} ><img src={require('../../Icons/flip-vertical.png')} /></Button>
                                </div>)}
                        </div>
                        {props.powerCounters && (props.powerCounters)}
                    </div>
                )
                }

                <DiceRolls playerData={props.playerData} />
            </div>
            <div class="first-player-image shadowed">
                <img src={require('../../Icons/first-player.png')} />
                <Button onClick={() => props.changeFirstPlayer()}>{t("game.game_section.next")}</Button>
            </div>
        </div >
    )
}

function ZoomableVideo(props) {
    const [offset, setOffset] = useState({ top: 0, left: 0 })
    const [offsetTarget, setOffsetTarget] = useState({ top: 0, left: 0 })

    useEffect(() => {
        const handleWindowMouseMove = e => {
            setOffset({ top: -3 * e.offsetY, left: -3 * e.offsetX })
            setOffsetTarget({ top: 0.75 * e.offsetY, left: 0.75 * e.offsetX })
        };
        window.addEventListener('mousemove', handleWindowMouseMove);

        return () => {
            window.removeEventListener(
                'mousemove',
                handleWindowMouseMove,
            );
        };
    }, []);

    return (
        <div class={props.isVideoZoomed == 1 ? "zoomed-video" : ""}>
            {props.isVideoZoomed == 0 && (<div class="target" style={{ position: 'absolute', ...offsetTarget }}></div>)}
            <ReactPlayer class="video-wrapper" volume={0} playing={true} muted={true} url={props.stream} width="100%" height="100%"
                style={props.isVideoZoomed == 1 ? { position: 'absolute', ...offset } : {}} />
        </div>)
}

export function DiceRolls(props) {
    return (
        <TransitionGroup>
            <div class="dice-roll-results d-flex flex-row justify-content-between">
                {props.playerData.diceRolls.length > 0 && (
                    <div class="d-flex flex-column">
                        {props.playerData.diceRolls.map((diceRoll, i) => {
                            return (<p class="glow" key={diceRoll.id}>Rolled a {diceRoll.result}/{diceRoll.sides}</p>)
                        })}
                    </div>
                )}
            </div>
        </TransitionGroup>)
}

function GameModeSetup(props) {
    const [setupMode, setSetupMode] = useState("undefined")
    const url = "https://exalts-table.com/mobile/"
    //const url = "https://192.168.1.4:3001/mobile/"

    const [errorMessage, setErrorMessage] = useState(false)
    const { t } = useLanguageContext()

    const useLocalCamera = () => {
        var getUserMedia = navigator.getUserMedia
            || navigator.webkitGetUserMedia
            || navigator.mozGetUserMedia;
        setErrorMessage(false)

        if (getUserMedia) {
            getUserMedia({
                video: {
                    width: { min: 640, ideal: 1920, max: 1920 },
                    height: { min: 480, ideal: 1080, max: 1080 },
                    framerate: { ideal: 30 }
                }, audio: false
            },
                (mediaStream) => {
                    props.replaceStream(mediaStream)
                    props.setGameMode("webcam")
                },
                (err) => {
                    console.error("Failed to get local stream", err);
                    setErrorMessage(err.toString())
                }
            );
        }
    }

    if (setupMode == "undefined") {
        return (
            <div class="position-relative">
                <div class="blurred-background"></div>
                <div class="d-flex flex-column justify-content-around">
                    <h2 class="mb-4">{t("game.game_section.setup.intro.title")}</h2>
                    <p>{t("game.game_section.setup.intro.content")}</p>
                    <div class="d-flex flex-row">
                        <Button onClick={() => setSetupMode("webcam")}>{t("game.game_section.setup.intro.webcam")}</Button>
                        <Button onClick={() => setSetupMode("virtual")}>{t("game.game_section.setup.intro.virtual")}</Button>
                    </div>
                </div>
            </div>
        )
    }

    if (setupMode == "virtual") {
        return (
            <VirtualSetup setSetupMode={setSetupMode} setGameMode={props.setGameMode} />
        )
    }

    if (setupMode == "webcam") {
        const setFakeStream = () => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");

            const img = new Image();
            img.onload = e => {
                canvas.width = img.naturalWidth;
                canvas.height = img.naturalHeight;
                let stream = canvas.captureStream()
                //streamRef.current.play()
                ctx.drawImage(img, 0, 0);
                // and if we try now to capture a new stream, we have a clear error
                //const stream2 = canvas.captureStream();
                props.replaceStream(stream)
                props.setGameMode("webcam")
            }
            img.src = require('../../test16.jpeg')
        }

        return (
            <div class="position-relative">
                <div class="blurred-background"></div>
                <div class="d-flex flex-column justify-content-around">
                    <h2 class="mb-4">{t("game.game_section.setup.webcam.title")}</h2>
                    <div class="d-flex flex-column">
                        <p>{t("game.game_section.setup.webcam.content_QR")}</p>
                        {props.playerRef.mobilePeer && (
                            <div class="QRCode">
                                <QRCode
                                    size={256}
                                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                    value={url + props.playerRef.mobilePeerId}
                                    viewBox={`0 0 256 256`}
                                />
                            </div>
                        )}
                        <p class="mt-4">{t("game.game_section.setup.webcam.content_desktop")}</p>
                        <Button class="h-auto m-0" onClick={useLocalCamera}>{t("game.game_section.setup.webcam.content_desktop_button")}</Button>
                        {/*<Button class="h-auto m-0" onClick={setFakeStream}>Use test stream</Button>*/}
                        {errorMessage && (<p>{errorMessage}</p>)}
                    </div>
                    <Button class="cancel-button" onClick={() => setSetupMode("undefined")}>{t("commons.cancel")}</Button>
                </div>
            </div>
        )
    }
}

function VirtualSetup(props) {
    const [heroName, setHeroName] = useState(false)
    const { t } = useLanguageContext()
    useEffect(() => {
        let item = window.localStorage.getItem("board-data")
        if (item) {
            let data = JSON.parse(item)
            setHeroName(data.hero ? (data.hero.name ? data.hero.name : data.hero.reference) : " ")
        }
    }, [])

    return (
        <div class="position-relative">
            <div class="blurred-background"></div>
            <div class="d-flex flex-column justify-content-around">
                <h2 class="mb-4">{t("game.game_section.setup.virtual.title")}</h2>
                
                                <p>{t("game.game_section.setup.virtual.content_decklist")}</p>
                <Button class="h-auto m-0" onClick={() => { props.setGameMode("virtual"); window.open('/deck-test', '_newtab'); }}>{t("game.game_section.setup.virtual.content_decklist_button")}</Button>

                {heroName && (
                    <div class="d-flex flex-column justify-content-around position-relative mt-4">
                        <p>{t("game.game_section.setup.virtual.content_load_existing")}</p>
                        <Button class="h-auto m-0" onClick={() => { props.setGameMode("virtual") }}>{t("game.game_section.setup.virtual.content_load_existing_button")} {heroName}</Button>
                    </div>
                )}
                
               

                <Button class="cancel-button" onClick={() => props.setSetupMode("undefined")}>{t("commons.cancel")}</Button>
            </div>
        </div>
    )
}

export function ButtonWithConfirmation(props) {
    const [showConfirmButton, setShowConfirmButton] = useState(false)

    if (showConfirmButton) {
        return (<div class="d-flex flex-row">
            <Button class={props.class} onClick={() => { setShowConfirmButton(false) }}><img src={require('../../Icons/x.png')} /></Button>
            <Button class={props.class} onClick={() => { props.onClick(); setShowConfirmButton(false) }}><img src={require('../../Icons/confirmer.png')} /></Button>
        </div>)
    }

    return (
        <Button class={props.class} onClick={() => setShowConfirmButton(true)}>
            {props.children}
        </Button>
    )
}



export const PlayerGameSectionMemo = React.memo(({ playerIndex, isYourSection, isSpectator, playerData, globalData, setPlayerGameData, focusedPlayerGameSection, setFocusedPlayerGameSection, changeFirstPlayer, firstPlayerIndex, updateBoardData, triggerVirtualDeckReset, resetVirtualDeck, isMini, newTurnVirtualDeck, zoomVirtualCard, playerRef, triggerVirtualChangeBackground, changeBackgroundVirtualDeck, useManualMode, powerCounters }) => {
    return (<PlayerGameSection
        playerIndex={playerIndex}
        isYourSection={isYourSection}
        playerData={playerData}
        globalData={globalData}
        setPlayerGameData={setPlayerGameData}
        focusedPlayerGameSection={focusedPlayerGameSection}
        setFocusedPlayerGameSection={setFocusedPlayerGameSection}
        changeFirstPlayer={changeFirstPlayer}
        firstPlayerIndex={firstPlayerIndex}
        updateBoardData={updateBoardData}
        triggerVirtualDeckReset={triggerVirtualDeckReset}
        resetVirtualDeck={resetVirtualDeck}
        triggerVirtualChangeBackground={triggerVirtualChangeBackground}
        changeBackgroundVirtualDeck={changeBackgroundVirtualDeck}
        isMini={isMini}
        newTurnVirtualDeck={newTurnVirtualDeck}
        zoomVirtualCard={zoomVirtualCard}
        playerRef={playerRef}
        useManualMode={useManualMode}
        isSpectator={isSpectator}
        powerCounters={powerCounters} />)
})

