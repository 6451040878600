import { useParams } from "react-router-dom";
import React, { useEffect, useRef } from "react";
import Peer from "peerjs";
import WaitingRoom from "./WaitingRoom";
import GameReborn from "../../Game/GameReborn";
import { chatDefault, globalDefault } from "./ConnectionHandlerHost";
import { containCrop } from "react-image-crop";
import { createEmptyMediaStream } from "../../QRCodeRecognition/Functions";
import PageWithNavigation from "../../Commons/PageWithNavigation";
import { pseudoDefault } from "../../Game/Components/GameDefaults";
import Draft from "../../Draft/Draft";

const { useState } = require("react")

export default function ConnectionHandler(props) {
    const { gameId } = useParams();
    const [isGameReady, setIsGameReady] = useState(false)

    const peerInstance = useRef(null)
    const playersRef = useRef([])
    const [playerCount, setPlayerCount] = useState(0)
    const hostId = "exalts-table-lobby-ID-"

    const [connectionMessage, setConnectionMessage] = useState("Connecting...")

    const [gameData, setGameData] = useState({
        global: { ...globalDefault },
        players: {},
        chat: { ...chatDefault }
    })
    const gameDataRef = useRef(false)

    const playerFaction = useRef(1)
    const hasGameStarted = useRef(false)
    const [userInfo, setUserInfo] = useState(() => {
        const storedUserName = localStorage.getItem('userName');
        if (storedUserName) {
            return {
                name: storedUserName,
                id: ""
            }
        }
        return {
            name: pseudoDefault.at(pseudoDefault.length * Math.random()),
            id: ""
        }
    })

    const lastDataRecievedTime = useRef(false)

    useEffect(() => {
        if (peerInstance.current == null) {
            iniPeer()
        }
    }, [])

    useEffect(() => {
        const unload = async (e) => {
            closeCall()
        }
        window.addEventListener('beforeunload', unload)
        return () => {
            window.removeEventListener(
                'beforeunload',
                unload,
            );
        };
    }, [])


    const iniPeer = () => {
        const peer = new Peer();

        peer.on('open', (id) => {
            console.log('My peer ID is: ' + id);
            playersRef.current.push({ connection: null, id: id, stream: createEmptyMediaStream(), calls: [] })
            setPlayerCount(playersRef.current.length)

            // Calling host
            call(hostId + gameId)
        });

        peer.on('call', (call) => {
            try {
                call.answer(playersRef.current[0].stream)
                call.on('stream', (remoteStream) => {
                    for (let i = 1; i < playersRef.current.length; i++) {
                        if (playersRef.current[i].id == call.peer) {
                            playersRef.current[i].stream = remoteStream
                            playersRef.current[0].calls.push(call)
                        }
                    }
                });
            } catch (error) {
                console.error(error);
            }
        })

        peer.on("connection", (conn) => {
            // Handle connection
            conn.on("open", () => {
                playersRef.current.push({ connection: conn, id: conn.peer, stream: false })
                setPlayerCount(playersRef.current.length)
            })
            conn.on("close", () => {
                closeConn(conn)
            })
            conn.on("data", (data) => {
                dataConn(conn, data)
            })
        })

        peerInstance.current = peer;
    }

    const call = (peerId) => {
        let conn = peerInstance.current.connect(peerId);
        setTimeout(() => {
            setConnectionMessage("Lobby full or closed")
        }, 4000)
        conn.on("open", () => {
            playersRef.current.push({ connection: conn, id: conn.peer, stream: false })
            setPlayerCount(playersRef.current.length)

            if (conn.peer == (hostId + gameId)) {
                sendDataToHost({
                    userInfo: userInfo
                })
            }

            // Call
            const call = peerInstance.current.call(peerId, playersRef.current[0].stream)
            call.on('stream', (remoteStream) => {
                for (let i = 1; i < playersRef.current.length; i++) {
                    if (playersRef.current[i].id == peerId) {
                        playersRef.current[i].stream = remoteStream
                        playersRef.current[0].calls.push(call)
                    }
                }
            });
        })
        conn.on("close", () => {
            closeConn(conn)
        })
        conn.on("data", (data) => {
            dataConn(conn, data)
        })
    }

    // DATA
    const [usersInfo, setUsersInfo] = useState(false)

    const dataConn = (conn, data) => {
        console.log("DATA RECIEVED from " + conn.peer)
        if (data.roomData) {
            data.roomData.playersIdArray.forEach((id) => {
                let index = playersRef.current.findIndex(a => a.id == id)
                if (index == -1) {
                    console.log("calling " + id)
                    call(id)
                }
            })
            let tmp = { ...gameData }
            tmp.global.gameModeInfo = data.roomData.gameModeInfo
            setGameData(tmp)
        } else if (data.usersInfo) {
            setUsersInfo(data.usersInfo)
            console.log("TEST", data.usersInfo)
        } else if (data.error) {
            setConnectionMessage(data.error)
        } else if (data.newMessage) {
            let tmp = { ...gameDataRef.current }
            tmp.chat.messages.push(data.newMessage)
            updateGameData(tmp)
        } else {
            handleGameData(data, conn.peer)
        }
    }

    const closeConn = (conn) => {
        if (gameData.global.gameModeInfo.modeId == "_draft") { return }
        if (hasGameStarted.current) {
            if (playersRef.current && playersRef.current[0].stream) {
                const tracks = playersRef.current[0].stream.getTracks();

                tracks.forEach((track) => {
                    track.stop();
                });
            }
            peerInstance.current.destroy()
            playersRef.current = []
            setIsGameReady(false)
            setConnectionMessage("Player left")
        } else {
            playersRef.current.splice(playersRef.current.findIndex(a => a.id == conn.peer), 1)
        }
        setPlayerCount(playersRef.current.length)
    }

    const sendDataToOtherPlayers = (data) => {
        playersRef.current.forEach((ref) => {
            if (ref.connection) {
                ref.connection.send(data)
            }
        })
    }

    const handleGameData = (data, senderId) => {
        if (senderId == playersRef.current[0].id) {
            console.log("ALARM")
            return
        }
        if (data.iniGameData) {
            console.log("IF CRASH", data.iniGameData.players)
            data.iniGameData.players[playersRef.current[0].id].faction = playerFaction.current
            sendDataToOtherPlayers({ time: new Date(), gameData: { player: data.iniGameData.players[playersRef.current[0].id] } })
            updateGameData(data.iniGameData)
            setIsGameReady(true)
            hasGameStarted.current = true
        } else if (data.gameData) {
            if (!hasGameStarted.current) { return }
            /*if (gameDataRef.current.players && Object.keys(gameDataRef.current.players).length == gameData.global.gameModeInfo.players) {
                setIsGameReady(true)
            }*/
            let tmp = { ...gameDataRef.current }
            if (data.gameData.global) {
                if (gameDataRef.current.global.gameModeInfo.modeId == "_draft") {
                    tmp.global = { ...data.gameData.global }
                } else {
                    if (lastDataRecievedTime.current && Date.parse(lastDataRecievedTime.current) > Date.parse(data.time)) { return }
                    lastDataRecievedTime.current = data.time
                    tmp.global = {
                        gameCount: data.gameData.global.gameCount,
                        tumulteCards: data.gameData.global.tumulteCards,
                        turnCounter: data.gameData.global.turnCounter > gameDataRef.current.global.turnCounter || data.gameData.global.gameCount != gameDataRef.current.global.gameCount ? data.gameData.global.turnCounter : gameDataRef.current.global.turnCounter,
                        firstPlayerIndex: data.gameData.global.firstPlayerIndex,
                        activePlayerIndex: data.gameData.global.activePlayerIndex,
                        turnOrder: data.gameData.global.turnOrder,
                        spectatorId: data.gameData.global.spectatorId,
                        gameModeInfo: data.gameData.global.gameModeInfo,
                        lastPlayedCard: data.gameData.global.lastPlayedCard
                    }
                }
            }
            if (data.gameData.player) {
                if (tmp.players && tmp.players[senderId]) {
                    tmp.players[senderId] = data.gameData.player
                }
            }
            if (data.gameData.newMessage) {
                let tmp = { ...gameDataRef.current }
                tmp.chat.messages.push(data.gameData.newMessage)
                updateGameData(tmp)
            }
            updateGameData(JSON.parse(JSON.stringify(tmp)))
        }
    }

    const sendGameData = (data) => {
        sendDataToOtherPlayers({ time: new Date(), gameData: data })
        if (data.player) {
            let tmp = { ...gameDataRef.current }
            tmp.players[playersRef.current[0].id] = data.player
            updateGameData(tmp)
        }
        if (data.global) {
            let tmp = { ...gameDataRef.current }
            tmp.global = data.global
            updateGameData(tmp)
        }
        if (data.newMessage) {
            let tmp = { ...gameDataRef.current }
            tmp.chat.messages.push(data.newMessage)
            updateGameData(tmp)
        }
    }

    const updateGameData = (data) => {
        setGameData({ ...data })
        gameDataRef.current = { ...data }
    }

    const closeCall = () => {
        if (gameData.global.gameModeInfo.modeId == "_draft") { return }
        if (playersRef.current[0].stream) {
            const tracks = playersRef.current[0].stream.getTracks();

            tracks.forEach((track) => {
                track.stop();
            });
        }

        peerInstance.current.destroy()
        playersRef.current = []

        setIsGameReady(false)
        setConnectionMessage("Disconnected")
    }

    const updateUserName = (newName) => {
        let newU = { ...userInfo }
        newU.name = newName
        setUserInfo(newU)
        sendDataToHost({ userInfo: newU })
    }

    const sendDataToHost = (data) => {
        playersRef.current[1].connection.send({ ...data })
    }

    if (playerCount <= 1) {
        return (<PageWithNavigation selected="play" component={
            <div class="d-flex flex-column justify-content-around">
                <h2>{connectionMessage}</h2>
                {connectionMessage == "Lobby full or closed" && (<p style={{ whiteSpace: "pre-line" }}>
                    {"Trouble connecting ? \n- Make sure all players are using a recent version of Chrome or Firefox.\n- If you have one, try disabling your VPN and if you don't have one, try with a VPN. You can also check your Firewall.\n- Make sure your browser has WebRTC enable (should be enabled by default in all browsers but might have been turned off)\n\nIf none of these things worked, you are probably part of a group of users whose network will prevent them from using this app and for whom I can't provide a solution at the moment due to budget reason.\n If that's the case, sorry."}
                </p>)}
            </div>
        } />)
    }

    if (!isGameReady) {
        return (<WaitingRoom currentPlayers={playerCount} roomId={gameId} setPlayerFaction={(val) => playerFaction.current = val} gameModeInfo={gameData.global.gameModeInfo} playerId={playersRef.current[0] ? playersRef.current[0].id : false} userInfo={userInfo} usersInfo={usersInfo} updateUserName={updateUserName} />)
    }

    if (isGameReady) {
        if (gameData.global.gameModeInfo.modeId == "_draft") {
            return (<Draft playersData={gameData.players} globalData={gameData.global} sendDataToOpponents={(data) => sendGameData(data)} playerId={playersRef.current[0].id} turnOrder={gameData.global.turnOrder} />)
        }
        return (<GameReborn playersRef={playersRef.current} playerId={playersRef.current[0].id} gameDataGlobal={gameData.global} gameDataPlayers={gameData.players} chat={gameData.chat} sendDataToOpponents={(data) => sendGameData(data)} endCall={() => closeCall()} />)
    }
}