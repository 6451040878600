import { useEffect, useRef, useState } from 'react'
import './Commons.css'

export default function Resizable(props) {
    const [divHeight, setDivHeight] = useState(window.innerHeight * 0.65)
    const [isDragging, setIsDragging] = useState(false)
    const isDraggingRef = useRef(false)


    useEffect(() => {
        const updateHeight = (e) => {
            if (isDraggingRef.current) {
                setDivHeight(window.innerHeight - e.clientY)
            }
        }
        const endDrag = () => {
            isDraggingRef.current = false
            setIsDragging(false)
        }
        window.addEventListener('pointermove', updateHeight);
        window.addEventListener('pointerup', endDrag);

        return () => {
            window.removeEventListener(
                'pointermove',
                updateHeight,
            );
            window.removeEventListener(
                'pointerup',
                endDrag,
            );
        };
    }, []);

    return(<div class={"position-relative" + (props.className ? " " + props.className : "")}>
        <div class={"pull-handle" + (isDragging ? " dragging" : "")}
            onPointerDown={() => {isDraggingRef.current = true; setIsDragging(true)}}
        ></div>
        <div class="resize-wrapper" style={{height: divHeight}}>
            {props.children}
        </div>
    </div>)
}