import { useState, useEffect, useRef } from 'react';
import GameReborn from '../GameReborn';
import { chatDefault, globalDefault, opponentDefault, tumulte2playersDefault } from '../../Home/Lobby/ConnectionHandlerHost';
import { gameModesInfo } from '../../Commons/GameModes';
import { getCardFromCardData, shuffle, uuidv4 } from '../../QRCodeRecognition/Functions';
import { useLanguageContext } from '../../Commons/Translations/languageContext';
import { powerCountersDefault, virtualBoardDefault } from '../Components/GameDefaults';
import cardsData from '../../Commons/Data/cards.json'

export default function SingleplayerGame() {
    const { i18n } = useLanguageContext()
    const [gameData, setGameData] = useState({
        global: {
            gameCount: 0,
            tumulteCards: JSON.parse(JSON.stringify(tumulte2playersDefault)),
            turnCounter: 1,
            gameModeInfo: { ...gameModesInfo["_1v1_standard"] },
            firstPlayerIndex: 0,
            activePlayerIndex: 0,
            turnOrder: ["1", "2"],
            lastPlayedCard: false
        },
        players: {
            "1": JSON.parse(JSON.stringify(opponentDefault)),
            "2": JSON.parse(JSON.stringify(opponentDefault))
        },
        chat: { ...chatDefault }
    })
    console.log(gameModesInfo["_1v1_standard"])

    const playersRef = useRef([
        { connection: null, id: "1", calls: [], stream: false },
        { connection: null, id: "2" }])

    const gameDataRef = useRef(gameData)

    useEffect(() => {
        let tmp = { ...gameDataRef.current }
        tmp.players["1"].powerCountersOpponentsId.hero = "2"
        tmp.players["1"].powerCountersOpponentsId.companion = "2"
        tmp.players["2"].powerCountersOpponentsId.hero = "1"
        tmp.players["2"].powerCountersOpponentsId.companion = "1"
        tmp.players["2"].gameMode = "virtual"
        tmp.players["2"].boardData = getChallengeOpponentDefaultData()

        updateGameData(tmp)
    }, [])

    const challengeDeckList = ["16 ALT_CORE_B_AX_08_C",
        "10 ALT_CORE_B_AX_09_C",
        "3 ALT_CORE_B_AX_25_C",
        "3 ALT_CORE_B_AX_30_C",
        "8 ALT_CORE_B_AX_15_C"].join("\n")

    const getChallengeOpponentDefaultData = () => {
        // Deck data
        let cardIdentifiers = challengeDeckList.split('\n')
        let deck = []

        while (cardIdentifiers.length) {
            let card = cardIdentifiers.shift()
            if (card.length > 1) {
                card = card.split(' ')
                if (card.length == 2) {
                    let cData = cardsData[card[1]]
                    if (cData) {
                        let c = getCardFromCardData(cData, i18n.language)
                        for (let i = 0; i < card[0]; i++) {
                            let c2 = {...c}
                            c2.cardId = uuidv4()
                            deck.push({ ...c2 })
                        }
                    }
                }
            }
        }
        shuffle(deck)
        let virtualBoard = JSON.parse(JSON.stringify(virtualBoardDefault))
        for (let n = 0; n < 3; n++) {
            virtualBoard.mana.push(deck.shift())
        }
        virtualBoard.deckList = [...deck]
        virtualBoard.deck = [...deck]

        return virtualBoard
    }


    const sendGameData = (data) => {
        let tmp = { ...gameDataRef.current }
        if (data.player) {
            tmp.players["1"] = data.player
        }
        if (data.global) {
            tmp.global = data.global
        }
        if (data.newMessage) {
            tmp.chat.messages.push(data.newMessage)
        }

        // Analyze data for possible turn to do
        if (data.global) {
            if (data.global.turnCounter != gameDataRef.current.global.turnCounter) {
                tmp.chat.messages.push({
                    sender: {
                        id: "2",
                        name: "bot"
                    },
                    message: "Starting turn " + data.global.turnCounter
                })

                let boardData = { ...tmp.players["2"].boardData }

                // On bouge tout en reserve
                endTurn(boardData)
                updatePowerCounters(tmp)

                // Draw X
                let deck = boardData.deck
                let hand = boardData.hand
                let mana = boardData.mana
                for (let n = 0; n < 2; n++) {
                    hand.push({ ...deck.shift() })
                }

                // Ajoute en mana toute carte qu'il ne peut pas jouer
                for (let i = 0; i < hand.length; i++) {
                    if (hand[i].manaCost && hand[i].manaCost[0] > mana.length) {
                        mana.push({ ...hand[i] })
                        hand.splice(i, 1)
                        i--
                    }
                }

                boardData.handSize = hand.length
                tmp.players["2"].boardData = boardData
            }

            if (data.global.activePlayerIndex == 1 && data.global.activePlayerIndex != gameDataRef.current.global.activePlayerIndex) {
                let boardData = { ...tmp.players["2"].boardData }
                tmp.chat.messages.push({
                    sender: {
                        id: "2",
                        name: "bot"
                    },
                    message: "A moi de jouer"
                })

                botTurnToPlay.current = true
                botTriesToPlayHisTurn()
            }

            if (data.global.gameCount > gameDataRef.current.global.gameCount) {
                tmp.players["2"].boardData = getChallengeOpponentDefaultData()
            }
        }

        updateGameData(tmp)
    }

    const botTurnToPlay = useRef(false)

    const botTriesToPlayHisTurn = () => {
        setTimeout(() => {
            if (botTurnToPlay.current && (gameDataRef.current.players["1"].boardData && !gameDataRef.current.players["1"].boardData.showEvening)) {
                let tmp = { ...gameDataRef.current }
                let boardData = { ...tmp.players["2"].boardData }
                let c = playOneCard(boardData)
                updatePowerCounters(tmp)
                // Detape tout son mana

                // Passe le tour
                tmp.global.activePlayerIndex = 0
                if (c) {
                    tmp.global.lastPlayedCard = { playerId: "2", card: {...c}}
                }

                tmp.players["2"].boardData = boardData
                
                botTurnToPlay.current = false
                updateGameData(tmp)
            } else {
                botTriesToPlayHisTurn()
            }
        }, 1000)
    }

    const endTurn = (boardData) => {
        let newExp = []
        for (let i = 0; i < boardData.heroExpedition.length; i++) {
            let card = boardData.heroExpedition[i]
            delete card.tapped
            let cardToPush = { ...card }

            if (card.tokens != undefined) {
                let tokens = card.tokens
                if (tokens.anchored || tokens.asleep) {
                    delete cardToPush.tokens.anchored
                    delete cardToPush.tokens.asleep
                    newExp.push(cardToPush)
                } else if (tokens.fleeting) {
                    delete cardToPush.tokens
                    if (card.type != "TOKEN") {
                        boardData.discard.push(cardToPush)
                    }
                } else {
                    if (card.type != "TOKEN") {
                        boardData.reserve.push(cardToPush)
                    }
                }
            } else {
                if (card.type != "TOKEN") {
                    boardData.reserve.push(cardToPush)
                }
            }
        }
        boardData.heroExpedition = [...newExp]
        newExp = []
        for (let i = 0; i < boardData.companionExpedition.length; i++) {
            let card = boardData.companionExpedition[i]
            delete card.tapped
            let cardToPush = { ...card }

            if (card.tokens != undefined) {
                let tokens = card.tokens
                if (tokens.anchored || tokens.asleep) {
                    delete cardToPush.tokens.anchored
                    delete cardToPush.tokens.asleep
                    newExp.push(cardToPush)
                } else if (tokens.fleeting) {
                    delete cardToPush.tokens
                    if (card.type != "TOKEN") {
                        boardData.discard.push(cardToPush)
                    }
                } else {
                    if (card.type != "TOKEN") {
                        boardData.reserve.push(cardToPush)
                    }
                }
            } else {
                if (card.type != "TOKEN") {
                    boardData.reserve.push(cardToPush)
                }
            }
        }
        boardData.companionExpedition = [...newExp]
        for (let i = 0; i < boardData.landmarks.length; i++) {
            delete boardData.landmarks[i].tapped
        }

        let sc1 = {...scarabot}
        sc1.cardId = uuidv4()
        let sc2 = {...scarabot}
        sc2.cardId = uuidv4()
        boardData.companionExpedition.push(sc1)
        boardData.heroExpedition.push(sc2)
    }

    const scarabot = {
        "name": "Scarabot",
        "image": "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_31/JPG/fr_FR/f524758a9b299aa4faf3e1b20e10c088.jpg",
        "id": "01HKAFJNMNGK46X8QT6X9WQ112",
        "type": "TOKEN",
        "reference": "ALT_CORE_B_AX_31_C",
        "manaCost": [
            0,
            0
        ],
        "power": [2, 2, 2]
    }

    const updatePowerCounters = (gameData) => {
        let playerData = gameData.players["2"]
        let boardData = playerData.boardData
        let newVirtualPower = JSON.parse(JSON.stringify(powerCountersDefault))
        boardData.heroExpedition.forEach((c) => {
            if (c.power) {
                let boost = c.tokens && c.tokens.boost ? c.tokens.boost : 0
                let asleep = c.tokens && c.tokens.asleep
                newVirtualPower.hero.forest += asleep ? 0 : Number(c.power[0]) + boost
                newVirtualPower.hero.mountain += asleep ? 0 : Number(c.power[1]) + boost
                newVirtualPower.hero.water += asleep ? 0 : Number(c.power[2]) + boost
            }
        })
        boardData.companionExpedition.forEach((c) => {
            if (c.power) {
                let boost = c.tokens && c.tokens.boost ? c.tokens.boost : 0
                let asleep = c.tokens && c.tokens.asleep
                newVirtualPower.companion.forest += asleep ? 0 : Number(c.power[0]) + boost
                newVirtualPower.companion.mountain += asleep ? 0 : Number(c.power[1]) + boost
                newVirtualPower.companion.water += asleep ? 0 : Number(c.power[2]) + boost
            }
        })
        playerData.powerCounters = newVirtualPower
    }

    const playOneCard = (boardData) => {
        // Joue la carte la moins cher de sa reserve puis de sa main quand reserve vide
        if (boardData.reserve.length > 0) {
            let min = 99999
            let minIndex
            for (let i = 0; i < boardData.reserve.length; i++) {
                if (boardData.reserve[i].manaCost[1] < min && boardData.reserve[i].manaCost[1] <= boardData.mana.length) {
                    minIndex = i
                    min = boardData.reserve[i].manaCost[1]
                }
            }
            let c = boardData.reserve[minIndex]
            boardData.reserve.splice(minIndex, 1)
            c.tokens = {}
            c.tokens.fleeting = true
            boardData.heroExpedition.push(c)
            return c
        } else if (boardData.hand.length > 0) {
            let min = 99999
            let minIndex
            for (let i = 0; i < boardData.hand.length; i++) {
                if (boardData.hand[i].manaCost[0] < min && boardData.hand[i].manaCost[0] <= boardData.mana.length) {
                    minIndex = i
                    min = boardData.hand[i].manaCost[0]
                }
            }
            let c = boardData.hand[minIndex]
            boardData.hand.splice(minIndex, 1)
            boardData.companionExpedition.push(c)
            boardData.handSize = boardData.hand.length
            return c
        }
        return false
    }

    const updateGameData = (data) => {
        setGameData({ ...data })
        gameDataRef.current = { ...data }
        // mettre le ref dans setgamedata
    }

    return (<GameReborn isHost={true} playersRef={playersRef.current} playerId={"1"} gameDataGlobal={gameData.global} gameDataPlayers={gameData.players} chat={gameData.chat} sendDataToOpponents={(data) => { sendGameData(data) }} endCall={() => { }} />);
}